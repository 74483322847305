import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DashboardJSON from './WalletManagement.json'
import { gamesActions, reportActions } from '../../_actions';
import ReactPaginate from 'react-paginate';
import { isMobile } from "react-device-detect";
import moment from 'moment'
import { PiExportBold } from "react-icons/pi";
import * as XLSX from 'xlsx';
import { Link } from 'react-router-dom';
import Loader from '../../components/Loader/Loader';


// import Table from '../../components/Atoms/Table/Table';


const WinList = () => {
  let selector = useSelector(state => state)
  const dispatch = useDispatch();

  const today = new Date().toISOString().split('T')[0];
  let { games } = selector ? selector : {};
  let { allMarket, allUserList } = games ? games : {};
  const [fieldsGame, setFieldsGame] = useState({});
  const [errorsGame, setErrorsGame] = useState({});




  // console.log('options.....', options);
  const { winList, winTotal, loading } = useSelector(state => state.reports);
  const { userList, userTotal } = useSelector(state => state.reports);
  const { gamesList, gamesTotal } = useSelector(state => state.games);
  const [size, setSize] = useState(10);
  const [offset, setOffset] = useState(0);
  const [pageNo, setPageNo] = useState(1);
  const [currentPage, setCurrentPage] = useState(0);
  const [keyWord, setkeyWord] = useState('');
  const [fromDate, setfromDate] = useState('');
  const [Options, setOptions] = useState([])
  const [toDate, settoDate] = useState('');
  const [selecteddata, setselecteddata] = useState([])
  useEffect(() => {
    let gameReq = {
      "fromDate": fromDate,
      "toDate": toDate,
      "keyWord": keyWord,
      "sortBy": "",
      "sortOrder": "",
      "pageNo": pageNo,
      "size": size,


    }

    let gameReq2 = {
      "fromDate": fromDate,
      "toDate": toDate,
      "keyWord": keyWord,
      "sortBy": "",
      "sortOrder": "",
      "pageNo": 1,
      "size": 500
    }
    dispatch(gamesActions.getAllGames(gameReq2))
    dispatch(reportActions.getWinningList(gameReq));
    dispatch(gamesActions.getAllMarket())
    dispatch(gamesActions.getAllUser())



  }, []);


  useEffect(() => {
    if (allUserList) {
      const options = allUserList?.map((user) => ({
        label: user.userName,
        value: user._id
      }));
      console.log(options);
      setOptions(options);
    }
  }, [allUserList])

  console.log(Options)
  const inputChangeCreate = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setFieldsGame(prevState => ({ ...prevState, [name]: value }));
    setErrorsGame(prevState => ({ ...prevState, [name]: "" }));
  };

  const createGameSubmit2 = () => {
    setFieldsGame({})
    let gameReq = {
      "fromDate": fromDate,
      "toDate": toDate,
      "keyWord": keyWord,
      "sortBy": "",
      "sortOrder": "",
      "pageNo": pageNo,
      "size": size,


    }
    dispatch(reportActions.getWinningList(gameReq));

  }

  const createGameSubmit = (e) => {
    e.preventDefault();
    // if (handleValidationCreateGame()) {
    // }
    console.log(fieldsGame)
    let gameReq2 = {
      "fromDate": fieldsGame?.date || "",
      // "toDate": fieldsGame?.todate || "",
      "keyWord": fieldsGame?.keyWord || "",
      "gameId": fieldsGame?.userId,
      "marketId": fieldsGame?.userId2,
      "pageNo": pageNo,
      "size": size,


    }
    dispatch(reportActions.getWinningList(gameReq2));
  };

  const handlePageClick = (data) => {
    console.log("data_page__page__page__page__page_???", data);
    let offset = Math.ceil(data.selected * size);
    setOffset(offset);
    setPageNo(data.selected + 1);
    let pageReq = {
      "fromDate": fromDate,
      "toDate": toDate,
      "keyWord": keyWord,
      "sortBy": "",
      "sortOrder": "",
      "pageNo": data.selected + 1,
      "size": size,

    }

    dispatch(reportActions.getWinningList(pageReq));
  }

  const handleInputSize = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setFieldsGame(prevState => ({ ...prevState, [name]: value }));
    setErrorsGame(prevState => ({ ...prevState, [name]: "" }));
    setSize(e.target.value)
    let pageReq = {
      // "type": "WITHDRAW",
      "keyWord": keyWord,
      "pageNo": 1,
      "sortBy": "slug",
      "sortOrder": "asc",
      "fromDate": fromDate,
      "toDate": toDate,
      "size": value,

    }
    dispatch(reportActions.getWinningList(pageReq));
  };
  const exportToExcel = () => {
    // Define column headers
    const headers = ["S.No.", "User Name", "Amount", "Bet Number", "Game Name", "Market Name", "Market Type", "Date", "Bet Status", "Profit/Loss"];

    // Map data to the required format
    const data = winList.map((element, index) => ({
      "S.No.": offset + index + 1,
      "User Name": element.userId?.userName || "-",
      "Amount": element.amount || "-",
      "Bet Number": element.betNumber || "-",
      "Game Name": element.gameId?.name || "-",
      "Market Name": element.marketId?.name || "-",
      "Market Type": element.isOpen ? "Open" : "Close",
      "Date": element.createdAt ? moment(new Date(parseInt(element.createdAt))).utcOffset("+05:30").format("DD-MM-YYYY hh:mm a") : "-",
      "Bet Status": element.betStatus === 0 ? "Pending" : element.betStatus === 1 ? "Win" : "Loss",
      "Profit/Loss": element.betStatus === 1 ? element.winAmount || "--" : element.betStatus === 2 ? element.lossAmount || "--" : "--"
    }));

    // Combine headers and data
    const worksheet = XLSX.utils.json_to_sheet(data);
    XLSX.utils.sheet_add_aoa(worksheet, [headers], { origin: "A1" });
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Win List");

    // Export the workbook
    XLSX.writeFile(workbook, "WinList.xlsx");
  };


  return (
    <>
      <Loader loading={loading} />
      <div className="z-0 overflow-hidden overflow-y-auto focus:outline-none">
        <div className="relative">
          <div className="relative z-20 flex min-h-screen">
            <div className="flex w-full overflow-hidden ">
              <div className="flex flex-col flex-1 overflow-hidden">
                <main className="relative flex-1 ">
                  <div className="p-3 2xl:p-10 sm:p-5">
                    <div className="mx-auto max-w-screen-3xl space-y-10">
                      {/* <sectiion className="flex justify-between my-4 ">
                        <div className='text-xl font-semibold'>Dashboard{" "}/{" "}<span className='text-[#7D1970]'>Bid History (Main Market)</span></div>
                      </sectiion> */}
                      <div className="px-6 py-2 text-left bg-white  rounded-lg">
                        <div className="flex items-center justify-between">
                          <span className="font-semibold ">Filter Winner List</span>
                        </div>
                        <div className='flex justify-between md:flex-nowrap gap-1  flex-wrap items-end  w-full '>

                          <div className="mt-1 shadow-sm w-full">
                            <label class="block text-gray-700 text-base mb-2" for="gamename">Date :</label>
                            <input className="w-full px-3 py-2 text-sm bg-white border border-gray-400 rounded-lg focus:outline-none uppercase"
                              id="date" name="date" placeholder="Enter date" value={fieldsGame && fieldsGame["date"] ? fieldsGame["date"] : ""} max={today} type="date" onChange={inputChangeCreate} />
                            {errorsGame && errorsGame["date"] ?
                              <div className="text-red-600 invalid-feedback">
                                {errorsGame["date"]}
                              </div>
                              : null}
                          </div>

                          {/* <div className="mt-1 shadow-sm w-full">
                            <label class="block text-gray-700 text-base mb-2" for="gamename">To Date :</label>
                            <input className="w-full px-3 py-2 text-sm bg-white border border-gray-400 rounded-lg focus:outline-none"
                              id="date" name="todate" placeholder="Enter date" value={fieldsGame && fieldsGame["todate"] ? fieldsGame["todate"] : ""} type="date" onChange={inputChangeCreate} />
                            {errorsGame && errorsGame["todate"] ?
                              <div className="text-red-600 invalid-feedback">
                                {errorsGame["todate"]}
                              </div>
                              : null}
                          </div> */}


                          <div className="mt-1 shadow-sm w-full">
                            <label class="block text-gray-700 text-base mb-2" for="name">Game Name :</label>
                            <select className="w-full px-3 py-2 text-sm bg-white border border-gray-400 rounded-lg focus:outline-none capitalize"
                              id="userId" name="userId" placeholder="name"
                              value={fieldsGame && fieldsGame["userId"] ? fieldsGame["userId"] : ""} type="text" onChange={inputChangeCreate} >
                              <option value="">Select Game Name</option>
                              {gamesList && gamesList.length > 0 ? gamesList.map((element, index) => (
                                <option value={element && element._id ? element._id : ""}>{element && element.name ? element.name : ""}</option>
                              )) : null}

                            </select>
                            {errorsGame && errorsGame["userId"] ?
                              <div className="text-red-600 invalid-feedback">
                                {errorsGame["userId"]}
                              </div>
                              : null}
                          </div>


                          <div className="mt-1 shadow-sm w-full">
                            <label class="block text-gray-700 text-base mb-2" for="name">Game Type :</label>
                            <select className="w-full px-3 py-2 text-sm bg-white border border-gray-400 rounded-lg focus:outline-none"
                              id="userId2" name="userId2" placeholder="name"
                              value={fieldsGame && fieldsGame["userId2"] ? fieldsGame["userId2"] : ""} type="text" onChange={inputChangeCreate} >
                              <option value="">Select Game Type</option>
                              {allMarket && allMarket.length > 0 ? allMarket.map((element, index) => (
                                <option value={element && element._id ? element._id : ""}>{element && element.name ? element.name : ""}</option>
                              )) : null}
                            </select>
                            {errorsGame && errorsGame["userId2"] ?
                              <div className="text-red-600 invalid-feedback">
                                {errorsGame["userId2"]}
                              </div>
                              : null}
                          </div>


                          <div className="w-full flex flex-wrap mt-2 md:mt-0 gap-3 justify-start items-end">
                            <button className="w-full md:w-auto flex justify-center px-4 py-2 text-base font-semibold text-white capitalize transition duration-150 ease-in-out bg-[#7D1970] border border-[#7D1970] rounded-xl focus:outline-none" type="button" onClick={createGameSubmit}>Submit</button>
                            <button className="w-full md:w-auto flex justify-center px-4 py-2 text-base font-semibold text-white capitalize transition duration-150 ease-in-out bg-[#7D1970] border border-[#7D1970] rounded-xl focus:outline-none" type="button" onClick={createGameSubmit2}>Reset</button>
                          </div>
                        </div>
                      </div>

                      <div className="px-6 py-2 text-left bg-white space-y-2 rounded-lg">
                      
                        <div className="relative overflow-x-auto ">
                          <div className='flex justify-between items-center px-3 py-2 border-b'>
                            <div>
                              <label class="block text-gray-700 text-base mb-2" for="name">Show Data</label>
                              <select className={`w-full px-3 py-2 text-sm bg-white border border-gray-400 rounded-md focus:outline-none`}
                                id="size" name="size" placeholder="name"
                                value={fieldsGame && fieldsGame["size"] ? fieldsGame["size"] : ""} type="text"
                                onChange={handleInputSize}
                              >
                                <option value="10">10</option>
                                <option value="20">20</option>
                                <option value="50">50</option>
                                <option value="100">100</option>

                              </select>
                              {/* <label class="block text-gray-700 text-base mb-2" for="name">entries</label> */}
                            </div>
                            <button onClick={exportToExcel} className="px-4 flex justify- items-center  gap-2 py-2 bg-blue-500 text-white rounded"><PiExportBold className='text-xl' />Export</button>
                          </div>


                          <table className="w-full text-sm text-left rtl:text-right text-gray-500 ">
                            <thead className="text-gray-900 capitalize bg-gray-50  ">
                              <tr>
                                <th className="px-6 py-3">S.No.</th>
                                <th className="px-6 py-3">user name</th>
                                <th className="px-6 py-3">amount</th>
                                <th className="px-6 py-3">Bet Number</th>
                                <th className="px-6 py-3">Game Name</th>
                                <th className="px-6 py-3">Market Name</th>
                                <th className="px-6 py-3">Market Type</th>
                                <th className="px-6 py-3">Date</th>
                                <th className="px-6 py-3">Bet Status</th>
                                <th className="px-6 py-3 flex justify-end items-end">Profit/Loss</th>
                              </tr>
                            </thead>
                            <tbody>
                              {winList && winList.length > 0 ? winList.map((element, index) => (
                                <React.Fragment key={index}>
                                  <tr key={index} className="bg-white border-b border-gray-400 capitalize">
                                    <th scope="row" className="px-6 py-4 font-medium text-gray-500 whitespace-nowrap ">
                                      {offset + index + 1}
                                    </th>
                                    <th scope="row" className="px-6 py-4 font-medium text-blue-500 whitespace-nowrap ">
                                      <Link to={`/app/user/${element.userId?._id}`}>{element && element.userId && element.userId.userName ? element.userId.userName : "-"}</Link>

                                    </th>
                                    <th scope="row" className="px-6 py-4 font-medium text-gray-500 whitespace-nowrap ">
                                      {element && element.amount ? element.amount : "-"}
                                    </th>
                                    <th scope="row" className="px-6 py-4 font-medium text-gray-500 whitespace-nowrap ">
                                      {element && element.betNumber ? element.betNumber : "-"}
                                    </th>
                                    <th scope="row" className="px-6 py-4 font-medium text-gray-500 whitespace-nowrap ">
                                      {element && element.gameId && element.gameId.name ? element.gameId.name : "-"}
                                    </th>
                                    <th scope="row" className="px-6 py-4 font-medium text-gray-500 whitespace-nowrap ">
                                      {element && element.marketId && element.marketId.name ? element.marketId.name : "-"}
                                    </th>
                                    <th scope="row" className="px-6 py-4 font-medium text-gray-500 whitespace-nowrap ">
                                      {element && element.isOpen && element.isOpen ? "Open" : "Close"}
                                    </th>
                                    <th scope="row" className="px-6 py-4 font-medium text-gray-500 whitespace-nowrap  uppercase">
                                      {element && element.createdAt ? moment(new Date(parseInt(element.createdAt))).utcOffset("+05:30").format("DD-MM-YYYY hh:mm a") : "-"}
                                    </th>
                                    {element.betStatus == 0 && <th scope="row" className="px-6 text-yellow-700 py-4 font-medium  whitespace-nowrap ">
                                      Pending
                                    </th>}
                                    {element.betStatus == 1 && <th scope="row" className="px-6 py-4 font-medium text-green-500 whitespace-nowrap ">
                                      Win
                                    </th>}
                                    {element.betStatus == 2 && <th scope="row" className="px-6 py-4 font-medium text-red-500 whitespace-nowrap ">
                                      Loss
                                    </th>}

                                    <td className="px-6 py-4 text-center">
                                      {element?.betStatus == 1 && <div className=' text-green-500'>{element?.winAmount || "--"}</div>}
                                      {element?.betStatus == 2 && <div className=' text-green-500'>{element?.lossAmount || "--"}</div>}
                                      {element?.betStatus == 0 && <div className=' text-yellow-700'>--</div>}

                                    </td>

                                  </tr>
                                </React.Fragment>
                              )) : (<tr className='text-black capitalize '>
                                <td className='text-center flex justify-center place-items-center'> No data Found</td>
                              </tr>)
                              }
                            </tbody>
                          </table>
                        </div>
                        {isMobile ?
                          <nav className="relative z-0 flex justify-end mt-5 w-76">
                            {
                              winTotal && winTotal > 10 ?
                                <ReactPaginate
                                  previousLabel={'Prev'}
                                  nextLabel={'Next'}
                                  breakLabel={'...'}
                                  breakClassName={'break-me'}
                                  pageCount={winTotal / size}
                                  marginPagesDisplayed={1}
                                  pageRangeDisplayed={1}
                                  onPageChange={handlePageClick}
                                  containerClassName={'pagination'}
                                  pageClassName={'page-cls'}
                                  activeClassName={'active'}
                                />
                                : null}
                          </nav> : <nav className="relative z-0 flex justify-end mt-5 w-76">
                            {
                              winTotal && winTotal > 10 ?
                                <ReactPaginate
                                  previousLabel={'Previous'}
                                  nextLabel={'Next'}
                                  breakLabel={'...'}
                                  breakClassName={'break-me'}
                                  pageCount={winTotal / size}
                                  marginPagesDisplayed={1}
                                  pageRangeDisplayed={1}
                                  onPageChange={handlePageClick}
                                  containerClassName={'pagination'}
                                  pageClassName={'page-cls'}
                                  activeClassName={'active'}
                                />
                                : null}
                          </nav>
                        }
                      </div>

                    </div>

                  </div>

                </main>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default WinList;