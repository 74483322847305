import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { IoMdCloseCircle } from "react-icons/io";
import { HiChevronDown, HiChevronUp } from "react-icons/hi";
import { MdOutlineCreditCard } from "react-icons/md";
import { FaHome } from "react-icons/fa";
import { FaUserGear } from "react-icons/fa6";
import { FaShoppingBasket } from "react-icons/fa";
import { FaRegNewspaper } from "react-icons/fa";
import { ImUsers } from "react-icons/im";
import { LuGamepad2 } from "react-icons/lu";
import { PiSlideshowBold } from "react-icons/pi";
import { BiWalletAlt } from "react-icons/bi";
import { TbReportAnalytics, TbSettingsUp } from "react-icons/tb";
// import { IoMdMenu } from "react-icons/io";
import { GiAngelWings } from "react-icons/gi";
import { MdNotificationsNone } from "react-icons/md";
import { GiHamburgerMenu } from "react-icons/gi";
import { MdKeyboardArrowRight } from "react-icons/md";
import { BsBank2 } from "react-icons/bs";
import { userActions } from "../../_actions";
import { useDispatch, useSelector } from "react-redux";
function Sidebar({ SidebarJSON, navbarOpen, setNavbarOpen }) {
  const location = useLocation();
  const navigate = useNavigate();

  const [showSection, setShowSection] = React.useState("");

  let selector = useSelector(state => state)
  let dispatch = useDispatch()

  const onClickMenu = (url) => {
    navigate(url);
    setTimeout(() => {
      setNavbarOpen(!navbarOpen);
    }, 150);
  };

  const handleNavbar = () => {
    setNavbarOpen(!navbarOpen);
  };

  let [localStoragedata, setlocalStoragedata] = useState();
  useEffect(() => {
    const stateData = window.localStorage.getItem("adminuser");
    dispatch(userActions.getAdminProfile());
    setlocalStoragedata(JSON.parse(stateData));
  }, []);
  console.log(localStoragedata);

  let { users } = selector ? selector : {};
  let { adminProfileDetails } = users ? users : {};
  let { adminData } = adminProfileDetails ? adminProfileDetails : {};
  let { access } = adminData ? adminData : {};

  const options = [
    { name: 'Declare Result', id: "Declare Result" },
    { name: 'Winner List', id: "Winner List" },
    { name: 'Setting Management', id: "Setting Management" },
    { name: 'Game Management', id: "Game Management" }
  ];

console.log("adminDataadminData",adminData)


// localStoragedata?.roleId == 0 || access && access.length > 0 && access.includes("Declare Result"

  return (
    <>
      <button
        className={` ${
          navbarOpen === true ? "hidden" : "flex"
        } absolute top-0 left-0 px-5 items-center justify-center border-r border-gray-200 text-white focus:outline-none focus:bg-gray-800 focus:text-gray-600 z-50 h-16 `}
        onClick={() => setNavbarOpen(!navbarOpen)}
      >
        <svg
          className="h-6 w-6"
          stroke="currentColor"
          fill="none"
          viewBox="0 0 24 24"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M4 6h16M4 12h16M4 18h7"
          ></path>
        </svg>
      </button>
      {/* lg:flex lg:static  */}
      <div
        className={`${
          navbarOpen === false ? "hidden" : "flex"
        } fixed left-0 inset-y-0   w-72 z-50 flex-col`}
      >
        <div className="flex   flex-col w-72 relative flex-1 h-full max-h-full">
          {/* onClick={() => onClickMenu('/app/dashboard')} */}
          <div className="cursor-pointer border-r relative flex items-center h-16 flex-shrink-0 px-4 bg-[#F64401] border-b border-gray-200 w-full z-50">
            <div className=" flex  justify-center gap-1 items-center">
              <img
                src={`/Img/matkaLogo.png`}
                alt=""
                className=" relative flex justify-center items-center  md:ml-6"
                width={"50px"}
              />
              <div className=" text-xl text-white font-bold">Indian Matka</div>
            </div>

            <div className="  items-end absolute right-3">
              <GiHamburgerMenu
                onClick={handleNavbar}
                className=" text-white text-xl  block"
              />
            </div>
          </div>
          <nav className="overflow-y-auto bg-white mt-2 pt-5 rounded-lg flex flex-col flex-wrap items-center justify-between relative  w-72 z-10 px-3  h-full overflow-auto">
            <div className="px-0 flex flex-col flex-wrap items-center w-full mx-auto flex-1">
              <div className={"relative z-40 flex-1 w-full space-y-1"}>
                <div className="">
                  <span
                    onClick={() =>
                      onClickMenu("/app/dashboard")
                    }
                    className={`cursor-pointer h-9 items-center flex space-x-4 px-1 py-2 text-sm  transition ease-in-out duration-150   ${
                      location.pathname === "/app/dashboard"
                        ? "text-white bg-[#F64401] rounded-md"
                        : "text-black   hover:text-[#F64401]"
                    }`}
                  >
                    <span className="group-hover:text-[#F64401]">
                      <FaHome size={20} />
                    </span>
                    <span className="text-sm  lg:font-lg ">Dashboard</span>
                  </span>
                  {/* <span
                    onClick={() => onClickMenu("/app/user")}
                    className={`cursor-pointer h-9 items-center flex space-x-4 px-1 py-2 text-sm transition ease-in-out duration-150   ${
                      location.pathname === "/app/user" ||
                      location.pathname.includes("/app/user/") ||
                      location.pathname.includes("/app/EditUser/")
                        ? "text-white bg-[#F64401] rounded-md"
                        : "text-black hover:text-[#F64401]"
                    }`}
                  >
                    <span className="group-hover:text-[#F64401]">
                      <FaUserGear size={20} />
                    </span>
                    <span className="text-sm  lg:font-lg">User Management</span>
                  </span> */}
                  {localStoragedata && (localStoragedata?.roleId == 0 || access && access.length > 0 && access.includes("Declare Result")) && (
                    <span
                      onClick={() =>
                        onClickMenu("/app/declareResult")
                      }
                      className={`cursor-pointer h-9 items-center flex space-x-4 px-1 py-2 text-sm transition ease-in-out duration-150    ${
                        location.pathname === "/app/declareResult"
                          ? "text-white bg-[#F64401] rounded-md"
                          : "text-black   hover:text-[#F64401]"
                      }`}
                    >
                      <span className="group-hover:text-[#F64401]">
                        <FaRegNewspaper size={20} />
                      </span>
                      <span className="text-sm  lg:font-lg">
                        Declare Result
                      </span>
                    </span>
                  )}

             {localStoragedata && (localStoragedata?.roleId == 0 || access && access.length > 0 && access.includes("Winner List"))   && <span
                    onClick={() =>
                      onClickMenu("/app/winnerList")
                    }
                    className={`cursor-pointer h-9 items-center flex space-x-4 px-1 py-2 text-sm transition ease-in-out duration-150    ${
                      location.pathname === "/app/winnerList"
                        ? "text-white bg-[#F64401] rounded-md"
                        : "text-black   hover:text-[#F64401]"
                    }`}
                  >
                    <span className="group-hover:text-[#F64401]">
                      <GiAngelWings size={20} />
                    </span>
                    <span className="text-sm  lg:font-lg">Winner List</span>
                  </span>}

                  <h2 className=" w-full h-[2px] bg-[#F64401] my-1"></h2>

                  {/* <span onClick={() => onClickMenu('/app/slider',handleNavbar())} className={`cursor-pointer h-9 items-center flex space-x-4 px-1 py-2 text-sm transition ease-in-out duration-150    ${location.pathname === "/app/slider" ? "text-white bg-[#F64401] rounded-md" : "text-black   hover:text-[#F64401]" }`}>
                                        <span className="group-hover:text-[#F64401]"><PiSlideshowBold size={20} /></span>
                                        <span className="text-sm  lg:font-lg">Slider Management</span>
                                    </span> */}
                  {/* <span onClick={() => onClickMenu('/app/appsetting',handleNavbar())} className={`cursor-pointer h-9 items-center flex space-x-4 px-1 py-2 text-sm transition ease-in-out duration-150    ${location.pathname === "/app/appsetting" ? "text-white bg-[#F64401] rounded-md" : "text-black   hover:text-[#F64401]" }`}>
                                        <span className="group-hover:text-[#F64401]"><MdOutlineCreditCard size={20} /></span>
                                        <span className="text-sm  lg:font-lg">App Setting Management</span>
                                    </span> */}

                  {/* {localStoragedata && localStoragedata.roleId == 0 && (
                    <span
                      onClick={() =>
                        onClickMenu("/app/bankdetails")
                      }
                      className={`cursor-pointer h-9 items-center flex space-x-4 px-1 py-2 text-sm transition ease-in-out duration-150    ${
                        location.pathname === "/app/bankdetails"
                          ? "text-white bg-[#F64401] rounded-md"
                          : "text-black   hover:text-[#F64401]"
                      }`}
                    >
                      <span className="group-hover:text-[#744939]">
                        <BsBank2 size={20} />
                      </span>
                      <span className="text-sm  lg:font-lg">
                       Admin Bank Setting
                      </span>
                    </span>
                  )} */}

                  {localStoragedata && localStoragedata.roleId == 0 && (
                    <span
                      onClick={() =>
                        onClickMenu("/app/subAdmin")
                      }
                      className={`cursor-pointer h-9 items-center flex space-x-4 px-1 py-2 text-sm transition ease-in-out duration-150    ${
                        location.pathname === "/app/subAdmin"
                          ? "text-white bg-[#F64401] rounded-md"
                          : "text-black   hover:text-[#F64401]"
                      }`}
                    >
                      <span className="group-hover:text-[#F64401]">
                        <ImUsers size={20} />
                      </span>
                      <span className="text-sm  lg:font-lg">Sub Admin</span>
                    </span>
                  )}
                  {/* <span
                    onClick={() =>
                      onClickMenu("/app/notification", handleNavbar())
                    }
                    className={`cursor-pointer h-9 items-center flex space-x-4 px-1 py-2 text-sm transition ease-in-out duration-150    ${
                      location.pathname === "/app/notification"
                        ? "text-white bg-[#F64401] rounded-md"
                        : "text-black   hover:text-[#F64401]"
                    }`}
                  >
                    <span className="group-hover:text-[#F64401]">
                      <MdNotificationsNone size={20} />
                    </span>
                    <span className="text-sm  lg:font-lg">
                      Notification Managemnt
                    </span>
                  </span> */}

                  {localStoragedata && (localStoragedata?.roleId == 0 || access && access.length > 0 && access.includes("Setting Management")) && (
                    <div className="cursor-pointer group">
                      {showSection === "settingManagement" ? (
                        <span
                          onClick={() => setShowSection("")}
                          className="flex items-center px-1 py-2 space-x-4 text-sm text-black transition duration-150 ease-in-out cursor-pointer h-9"
                        >
                          <span className="group-hover:text-[#F64401]">
                            <TbSettingsUp size={20} />
                          </span>
                          <span className="text-sm group-hover:text-[#F64401]  lg:font-lg">
                            Setting Management
                          </span>
                          {showSection === "settingManagement" ? (
                            <HiChevronUp className="group-hover:text-[#F64401]" />
                          ) : (
                            <HiChevronDown className="group-hover:text-[#F64401]" />
                          )}
                        </span>
                      ) : (
                        <span
                          onClick={() => setShowSection("settingManagement")}
                          className="flex items-center px-1 py-2 space-x-4 text-sm text-black transition duration-150 ease-in-out cursor-pointer h-9"
                        >
                          <span className="group-hover:text-[#F64401]">
                            <TbSettingsUp size={20} />{" "}
                          </span>
                          <span className="text-sm group-hover:text-[#F64401]  lg:font-lg">
                            Setting Management
                          </span>
                          {showSection === "settingManagement" ? (
                            <HiChevronUp className="group-hover:text-[#F64401]" />
                          ) : (
                            <HiChevronDown className="group-hover:text-[#F64401]" />
                          )}
                        </span>
                      )}
                      {showSection === "settingManagement" ? (
                        <div className="  flex flex-col pl-10">
                          <span
                            onClick={() =>
                              onClickMenu("/app/appsetting")
                            }
                            className={`cursor-pointer h-9 items-center flex space-x-4 px-1 py-2 text-sm transition ease-in-out duration-150    ${
                              location.pathname === "/app/appsetting"
                                ? "text-white bg-[#F64401] rounded-md"
                                : "text-black   hover:text-[#F64401]"
                            }`}
                          >
                            <MdKeyboardArrowRight />{" "}
                            <span className="text-sm  lg:font-lg">
                              App Setting
                            </span>
                          </span>
                          <span
                            onClick={() =>
                              onClickMenu("/app/slider")
                            }
                            className={`cursor-pointer h-9 items-center flex space-x-4 px-1 py-2 text-sm transition ease-in-out duration-150    ${
                              location.pathname === "/app/slider"
                                ? "text-white bg-[#F64401] rounded-md"
                                : "text-black   hover:text-[#F64401]"
                            }`}
                          >
                            <MdKeyboardArrowRight />{" "}
                            <span className="text-sm  lg:font-lg">
                              Slider Setting
                            </span>
                          </span>
                        </div>
                      ) : null}
                    </div>
                  )}

                  <h2 className=" w-full h-[2px] bg-[#F64401] my-1"></h2>
                </div>

                {localStoragedata && (localStoragedata?.roleId == 0 || access && access.length > 0 && access.includes("Game Management")) && (
                  <div className="cursor-pointer group">
                    {showSection === "game" ? (
                      <span
                        onClick={() => setShowSection("")}
                        className="flex items-center px-1 py-2 space-x-4 text-sm text-black transition duration-150 ease-in-out cursor-pointer h-9"
                      >
                        <span className="group-hover:text-[#F64401]">
                          <LuGamepad2 size={20} />
                        </span>
                        <span className="text-sm group-hover:text-[#F64401]  lg:font-lg">
                          Game Management
                        </span>
                        {showSection === "game" ? (
                          <HiChevronUp className="group-hover:text-[#F64401]" />
                        ) : (
                          <HiChevronDown className="group-hover:text-[#F64401]" />
                        )}
                      </span>
                    ) : (
                      <span
                        onClick={() => setShowSection("game")}
                        className="flex items-center px-1 py-2 space-x-4 text-sm text-black transition duration-150 ease-in-out cursor-pointer h-9"
                      >
                        <span className="group-hover:text-[#F64401]">
                          <LuGamepad2 size={20} />{" "}
                        </span>
                        <span className="text-sm group-hover:text-[#F64401]  lg:font-lg">
                          Game Management
                        </span>
                        {showSection === "game" ? (
                          <HiChevronUp className="group-hover:text-[#F64401]" />
                        ) : (
                          <HiChevronDown className="group-hover:text-[#F64401]" />
                        )}
                      </span>
                    )}
                    {showSection === "game" ? (
                      <div className=" mb-5  flex flex-col pl-10">
                        {/* <span
                          onClick={() =>
                            onClickMenu("/app/game")
                          }
                          className={`cursor-pointer h-9 items-center flex space-x-4 px-1 py-2 text-sm transition ease-in-out duration-150    ${
                            location.pathname === "/app/game"
                              ? "text-white bg-[#F64401] rounded-md"
                              : "text-black   hover:text-[#F64401]"
                          }`}
                        >
                   
                          <MdKeyboardArrowRight />{" "}
                          <span className="text-sm  lg:font-lg">Game </span>
                        </span> */}

                        <span
                          onClick={() =>
                            onClickMenu("/app/market")
                          }
                          className={`cursor-pointer h-9 items-center flex space-x-4 px-1 py-2 text-sm transition ease-in-out duration-150    ${
                            location.pathname === "/app/market" ||
                            location.pathname.includes("app/EditMarket/")
                              ? "text-white bg-[#F64401] rounded-md"
                              : "text-black   hover:text-[#F64401]"
                          }`}
                        >
                          {/* <span className="group-hover:text-[#F64401]"><FaShoppingBasket size={20} /></span> */}
                          <MdKeyboardArrowRight />{" "}
                          <span className="text-sm  lg:font-lg">
                            Market Management
                          </span>
                        </span>
                      </div>
                    ) : null}
                  </div>
                )}
               {localStoragedata&&localStoragedata?.roleId == 0&& <div className="cursor-pointer group">
                  {showSection === "wallet" ? (
                    <span
                      onClick={() => setShowSection("")}
                      className="flex items-center px-1 py-2 space-x-4 text-sm text-black transition duration-150 ease-in-out cursor-pointer h-9"
                    >
                      <span className="group-hover:text-[#F64401]">
                        <BiWalletAlt size={20} />
                      </span>
                      <span className="text-sm group-hover:text-[#F64401]  lg:font-lg">
                        Wallet Management
                      </span>
                      {showSection === "wallet" ? (
                        <HiChevronUp className="group-hover:text-[#F64401]" />
                      ) : (
                        <HiChevronDown className="group-hover:text-[#F64401]" />
                      )}
                    </span>
                  ) : (
                    <span
                      onClick={() => setShowSection("wallet")}
                      className="flex items-center px-1 py-2 space-x-4 text-sm text-black transition duration-150 ease-in-out cursor-pointer h-9"
                    >
                      <span className="group-hover:text-[#F64401]">
                        <BiWalletAlt size={20} />{" "}
                      </span>
                      <span className="text-sm group-hover:text-[#F64401]  lg:font-lg">
                        Wallet Management
                      </span>
                      {showSection === "wallet" ? (
                        <HiChevronUp className="group-hover:text-[#F64401]" />
                      ) : (
                        <HiChevronDown className="group-hover:text-[#F64401]" />
                      )}
                    </span>
                  )}
                  {showSection === "wallet" ? (
                    <div className=" mb-5  flex flex-col pl-10">
                      {/* <span
                        onClick={() =>
                          onClickMenu("/app/withdrawRequest")
                        }
                        className={`cursor-pointer h-9 items-center flex space-x-4 px-1 py-2 text-sm transition ease-in-out duration-150    ${
                          location.pathname === "/app/withdrawRequest"
                            ? "text-white bg-[#F64401] rounded-md"
                            : "text-black   hover:text-[#F64401]"
                        }`}
                      >
                        <MdKeyboardArrowRight />{" "}
                        <span className="text-sm  lg:font-lg">
                          Withdraw Request
                        </span>
                      </span> */}

                      {/* <span
                        onClick={() =>
                          onClickMenu("/app/AddFundRequest")
                        }
                        className={`cursor-pointer h-9 items-center flex space-x-4 px-1 py-2 text-sm transition ease-in-out duration-150    ${
                          location.pathname === "/app/AddFundRequest"
                            ? "text-white bg-[#F64401] rounded-md"
                            : "text-black   hover:text-[#F64401]"
                        }`}
                      >
                        <MdKeyboardArrowRight />{" "}
                        <span className="text-sm  lg:font-lg">
                          Add Fund Request
                        </span>
                      </span> */}

                      <span
                        onClick={() =>
                          onClickMenu("/app/addFund")
                        }
                        className={`cursor-pointer h-9 items-center flex space-x-4 px-1 py-2 text-sm transition ease-in-out duration-150    ${
                          location.pathname === "/app/addFund"
                            ? "text-white bg-[#F64401] rounded-md"
                            : "text-black   hover:text-[#F64401]"
                        }`}
                      >
                        <MdKeyboardArrowRight />{" "}
                        <span className="text-sm  lg:font-lg">
                          Add Fund (User Wallet)
                        </span>
                      </span>

                      <span
                        onClick={() =>
                          onClickMenu("/app/withdrawfund")
                        }
                        className={`cursor-pointer  whitespace-nowrap h-9 items-center flex space-x-4  py-2 text-sm transition ease-in-out duration-150    ${
                          location.pathname === "/app/withdrawfund"
                            ? "text-white bg-[#F64401] rounded-md"
                            : "text-black   hover:text-[#F64401]"
                        }`}
                      >
                        <MdKeyboardArrowRight />{" "}
                        <span className="text-sm  lg:font-lg">
                          Withdraw Fund (User Wallet)
                        </span>
                      </span>
                    </div>
                  ) : null}
                </div>}
{localStoragedata&&localStoragedata?.roleId == 0&& 
                <div className="cursor-pointer group">
                  {showSection === "report" ? (
                    <span
                      onClick={() => setShowSection("")}
                      className="flex items-center px-1 py-2 space-x-4 text-sm text-black transition duration-150 ease-in-out cursor-pointer h-9"
                    >
                      <span className="group-hover:text-[#F64401]">
                        <TbReportAnalytics size={20} />
                      </span>
                      <span className="text-sm group-hover:text-[#F64401]  lg:font-lg">
                        Report Management
                      </span>
                      {showSection === "report" ? (
                        <HiChevronUp className="group-hover:text-[#F64401]" />
                      ) : (
                        <HiChevronDown className="group-hover:text-[#F64401]" />
                      )}
                    </span>
                  ) : (
                    <span
                      onClick={() => setShowSection("report")}
                      className="flex items-center px-1 py-2 space-x-4 text-sm text-black transition duration-150 ease-in-out cursor-pointer h-9"
                    >
                      <span className="group-hover:text-[#F64401]">
                        <TbReportAnalytics size={20} />{" "}
                      </span>
                      <span className="text-sm group-hover:text-[#F64401]  lg:font-lg">
                        Report Management
                      </span>
                      {showSection === "report" ? (
                        <HiChevronUp className="group-hover:text-[#F64401]" />
                      ) : (
                        <HiChevronDown className="group-hover:text-[#F64401]" />
                      )}
                    </span>
                  )}
                  {showSection === "report" ? (
                    <div className=" mb-5  flex flex-col pl-10">
                      <span
                        onClick={() =>
                          onClickMenu("/app/betHistory")
                        }
                        className={`cursor-pointer h-9 items-center flex space-x-4 px-1 py-2 text-sm transition ease-in-out duration-150    ${
                          location.pathname === "/app/betHistory"
                            ? "bg-[#F64401] text-white  rounded-lg "
                            : "text-black   hover:text-[#F64401]"
                        }`}
                      >
                        <MdKeyboardArrowRight />{" "}
                        <span className="text-sm  lg:font-lg">
                          Bid Point History
                        </span>
                      </span>
                      <span
                        onClick={() =>
                          onClickMenu("/app/userbidhistory")
                        }
                        className={`cursor-pointer h-9 items-center flex space-x-4 px-1 py-2 text-sm transition ease-in-out duration-150    ${
                          location.pathname === "/app/userbidhistory"
                            ? "bg-[#F64401] text-white  rounded-lg "
                            : "text-black   hover:text-[#F64401]"
                        }`}
                      >
                        <MdKeyboardArrowRight />{" "}
                        <span className="text-sm  lg:font-lg">
                          User Bid History
                        </span>
                      </span>
                      <span
                        onClick={() =>
                          onClickMenu("/app/addFundReport")
                        }
                        className={`cursor-pointer h-9 items-center flex space-x-4 px-1 py-2 text-sm transition ease-in-out duration-150    ${
                          location.pathname === "/app/addFundReport"
                            ? "bg-[#F64401] text-white  rounded-lg "
                            : "text-black   hover:text-[#F64401]"
                        }`}
                      >
                        <MdKeyboardArrowRight />{" "}
                        <span className="text-sm  lg:font-lg">
                          Add Fund Report
                        </span>
                      </span>

                      <span
                        onClick={() =>
                          onClickMenu("/app/withdrawReport")
                        }
                        className={`cursor-pointer h-9 items-center flex space-x-4 px-1 py-2 text-sm transition ease-in-out duration-150    ${
                          location.pathname === "/app/withdrawReport"
                            ? "bg-[#F64401] text-white  rounded-lg "
                            : "text-black   hover:text-[#F64401]"
                        }`}
                      >
                        <MdKeyboardArrowRight />{" "}
                        <span className="text-sm  lg:font-lg">
                          User Withdraw Fund Report
                        </span>
                      </span>
                      {/* <span onClick={() => onClickMenu('/app/chart', handleNavbar())} className={`cursor-pointer h-9 items-center flex space-x-4 px-1 py-2 text-sm transition ease-in-out duration-150    ${location.pathname === "/app/chart" ? "bg-[#F64401] text-white  rounded-lg " : "text-black   hover:text-[#F64401]"}`}>
                                                <MdKeyboardArrowRight />      <span className="text-sm  lg:font-lg">Charts</span>
                                            </span> */}

                      {/* {localStoragedata && localStoragedata.roleId == 0 && (
                        <span
                          onClick={() =>
                            onClickMenu("/app/pointTransfer")
                          }
                          className={`cursor-pointer h-9 whitespace-nowrap items-center flex space-x-4 px-1 py-2 text-sm transition ease-in-out duration-150  ${
                            location.pathname === "/app/pointTransfer"
                              ? "bg-[#F64401] text-white  rounded-lg "
                              : "text-black   hover:text-[#F64401]"
                          }`}
                        >
                          <MdKeyboardArrowRight />{" "}
                          <span className="text-sm  lg:font-lg">
                            Point Transfer Report
                          </span>
                        </span>
                      )} */}
                    </div>
                  ) : null}
                </div>}
              </div>
            </div>
          </nav>
        </div>
      </div>
    </>
  );
}

export default Sidebar;
