import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DashboardJSON from './WalletManagement.json'
import { gamesActions, walletActions } from '../../_actions';
import { TfiEye } from "react-icons/tfi";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import ReactPaginate from 'react-paginate';
import { isMobile } from "react-device-detect";
import ViewGameModal from './components/ViewGameModal/ViewGameModal';
import Loader from '../../components/Loader/Loader';
import * as XLSX from 'xlsx';
import { PiExportBold } from "react-icons/pi";
import moment from 'moment';


const AddFundReport = () => {
  const maxDate = new Date().toISOString().split('T')[0];
  const exportToExcel = () => {
    // Define your column headers
    const headers = ["S.No.", "User Name", "Amount", "Receipt Image", "Date", "Status"];

    // Map your data to the required format
    const data = paymentList.map((element, index) => ({
      "S.No.": offset + index + 1,
      "User Name": element.userId?.userName || "-",
      "Amount": element.amount || "-",
      "Receipt Image": element.image || "-",
      "Date": element.createdAt ? moment(new Date(element.createdAt)).format("YYYY-MM-DD hh:mm:ss a") : "-",
      "Status": element.status === 1 ? "Approved" : element.status === 2 ? "Rejected" : "Pending"
    }));

    // Create a worksheet and add headers
    const worksheet = XLSX.utils.json_to_sheet([]);
    XLSX.utils.sheet_add_aoa(worksheet, [headers], { origin: "A1" });
    XLSX.utils.sheet_add_json(worksheet, data, { origin: "A2", skipHeader: true });

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Report");

    // Export the workbook
    XLSX.writeFile(workbook, "Report.xlsx");
  };
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(0);
  let [selectedFile, setSelectedFile] = useState(null);
  let [rowData, setRowData] = useState(null);
  let [createModal, setCreateModal] = useState(false);
  let [updateModal, setUpdateModal] = useState(false);
  let [viewModal, setViewModal] = useState(false);
  const [marketSelected, setMarketSelected] = useState([]);
  const [fieldsUpdate, setFieldsUpdate] = useState({});
  const [errorsUpdate, setErrorsUpdate] = useState({});
  const [fieldsGame, setFieldsGame] = useState({});
  const [errorsGame, setErrorsGame] = useState({});
  const [selectedMarket, setSelectedMarket] = useState({});
  const [size, setSize] = useState(10);
  const [offset, setOffset] = useState(0);
  const [pageNo, setPageNo] = useState(1);
  const [fromDate, setfromDate] = useState("");
  const [toDate, settoDate] = useState("");
  const [keyWord, setkeyWord] = useState("");

  const { paymentList, totalPayment, loading } = useSelector(state => state.wallet);

  const selector = useSelector(state => state)
  const { games } = selector ? selector : {};
  let { allUserList } = games ? games : {};



  useEffect(() => {
    let gameReq = {
      "fromDate": fromDate,
      "toDate": toDate,
      "keyWord": keyWord,
      "sortBy": "",
      "sortOrder": "",
      "type": "DEPOSIT",
      "pageNo": pageNo,
      "size": size
    }
    dispatch(walletActions.getApproveRejectPaymentListForAdmin(gameReq));
    dispatch(gamesActions.getAllUser());

  }, []);




  const handleViewOpenModal = (data) => {
    console.log(data)
    setViewModal(true);
    console.log(data)
    setRowData(data);
  }

  const handleViewHideModal = (data) => {
    setViewModal(false);
    setRowData({});
  }


  const createGameSubmit2 = () => {
    let gameReq = {
      "fromDate": fromDate,
      "toDate": toDate,
      "keyWord": keyWord,
      "sortBy": "",
      "sortOrder": "",
      "type": "DEPOSIT",
      "pageNo": pageNo,
      "size": size
    }
    if (fromDate !== "" && toDate !== "" || fromDate == "" && toDate == "") {

      dispatch(walletActions.getApproveRejectPaymentListForAdmin(gameReq));
    }
  }

  const handlePageClick = (data) => {
    console.log("data_page__page__page__page__page_???", data);
    let offset = Math.ceil(data.selected * size);
    setCurrentPage(data.selected)
    setOffset(offset);
    setPageNo(data.selected + 1);
    let pageReq = {
      "fromDate": "",
      "toDate": "",
      "keyWord": keyWord,
      "sortBy": "",
      "sortOrder": "",
      "type": "DEPOSIT",
      "pageNo": data.selected + 1,
      "size": size
    }
    dispatch(walletActions.getApproveRejectPaymentListForAdmin(pageReq));
  }
  // console.log(paymentList)



  return (
    <>
      <Loader loading={loading} />
      <div className="z-0 overflow-hidden overflow-y-auto focus:outline-none">
        <div className="relative">
          <div className="relative z-20 flex min-h-screen">
            <div className="flex w-full overflow-hidden ">
              <div className="flex flex-col flex-1 overflow-hidden">
                <main className="relative flex-1 ">
                  <div className="p-3 2xl:p-10 sm:p-5">
                    <div className="mx-auto max-w-screen-3xl space-y-5">
                      <sectiion className="flex flex-col lg:flex-row justify-between my-4 ">
                        <div className='text-xl font-semibold'>Dashboard{" "}/{" "}
                          <span className='text-[#7D1970]'>User Fund Add Report</span>
                        </div>
                        <div className="px-6 text-left   rounded-lg">
                          <div className='flex  justify-end w-full space-x-5'>
                            <div className='flex flex-wrap md:flex-nowrap justify-between items-end gap-3'>
                              <div className="mt-1 shadow-sm w-full">
                                <label class="block text-gray-700 text-base font-bold mb-2" for="name"> User :</label>
                                <select className="w-full px-3 py-2.5 text-sm bg-white border border-gray-400 rounded-lg focus:outline-none"
                                  id="userId" name="userId" placeholder="name"
                                  value={keyWord && keyWord ? keyWord : ""} type="text" onChange={(e) => setkeyWord(e.target.value)} >
                                  <option value="">Select User</option>

                                  {allUserList && allUserList.length > 0 ? allUserList.map((element, index) => (
                                    <option value={element && element._id ? element._id : ""}>{element && element.userName ? element.userName : ""}</option>
                                  )) : null}
                                </select>
                                {errorsGame && errorsGame["userId"] ?
                                  <div className="text-yellow-600 invalid-feedback">
                                    {errorsGame["userId"]}
                                  </div>
                                  : null}
                              </div>


                              <div className="w-full mt-1 shadow-sm">
                                <label class="block text-gray-700 text-base font-bold mb-2" for="gamename">From Date:</label>
                                <input className="w-full px-3 py-2 text-sm bg-white border border-gray-400 rounded-lg focus:outline-none uppercase"
                                  id="date" name="date" placeholder="Enter date" value={fromDate} max={maxDate} type="date" onChange={(e) => setfromDate(e.target.value)} />
                                {errorsGame && errorsGame["date"] ?
                                  <div className="text-yellow-600 invalid-feedback">
                                    {errorsGame["date"]}
                                  </div>
                                  : null}
                              </div>
                              <div className="w-full mt-1 shadow-sm">
                                <label class="block text-gray-700 text-base font-bold mb-2" for="gamename">To Date:</label>
                                <input className="w-full px-3 py-2 text-sm bg-white border border-gray-400 rounded-lg focus:outline-none uppercase"
                                  id="date" name="date" placeholder="Enter date" value={toDate} max={maxDate} type="date" onChange={(e) => settoDate(e.target.value)} />
                                {errorsGame && errorsGame["date"] ?
                                  <div className="text-yellow-600 invalid-feedback">
                                    {errorsGame["date"]}
                                  </div>
                                  : null}
                              </div>
                              <div className="w-full flex flex-col md:flex-row justify-between  gap-3">
                                <button className="flex justify-center px-4 py-2 text-base font-semibold text-white capitalize  transition duration-150 ease-in-out bg-[#7D1970] border border-[#7D1970] rounded-xl focus:outline-none" type="button" onClick={createGameSubmit2}>Submit</button>
                                <button onClick={exportToExcel} className="px-4 flex justify-center items-center  gap-2 py-2 bg-blue-500 text-white rounded-xl"><PiExportBold className='text-xl' />Export</button>

                              </div>
                            </div>
                          </div>
                        </div>

                      </sectiion>


                      <div>
                        <div className="relative overflow-x-auto shadow-md sm:rounded-lg border">

                          <table className="w-full text-sm text-left rtl:text-right text-gray-500 ">
                            <thead className="text-gray-900 capitalize bg-gray-50  ">
                              <tr>
                                <th scope="col" className="px-6 py-3">S.No.</th>
                                <th scope="col" className="px-6 py-3">user name</th>
                                <th scope="col" className="px-6 py-3">amount</th>
                                {/* <th scope="col" className="px-6 py-3">Points</th> */}
                                <th scope="col" className="px-6 py-3">Reciept Image</th>
                                <th scope="col" className="px-6 py-3">Date</th>
                                <th scope="col" className="px-6 py-3">Status</th>
                                {/* <th scope="col" className="px-6 py-3 text-right">action</th> */}
                              </tr>
                            </thead>
                            <tbody>
                              {paymentList && paymentList.length > 0 ? paymentList.map((element, index) => {
                                if (element.status != 0) {
                                  return (
                                    <React.Fragment key={index}>
                                      <tr key={index} className={element.status != 0 && `bg-white border-b border-gray-400 capitalize`}>
                                        <th scope="row" className="px-6 py-4 font-medium text-gray-500 whitespace-nowrap ">
                                          {offset + index + 1}
                                        </th>
                                        <th scope="row" className="px-6 py-4 font-medium text-gray-500 whitespace-nowrap ">
                                          {element && element.userId ? element?.userId?.userName : "-"}
                                        </th>
                                        <th scope="row" className="px-6 py-4 font-medium text-gray-500 whitespace-nowrap ">
                                          {element && element.amount ? element.amount : "-"}
                                        </th>
                                        <td className="px-6 flex justify-start py-4">
                                          <TfiEye onClick={() => handleViewOpenModal(element)} className=' text-3xl text-[#7D1970] cursor-pointer' />
                                          {/* <img src={element && element.image ? element.image : "-"}
                                        alt=" " height="50px" width="50px" /> */}
                                        </td>
                                        <th scope="row" className="px-6 py-4 font-medium text-gray-500 whitespace-nowrap ">
                                          {element && element.createdAt ? new Date(element.createdAt).toLocaleString() : "-"}
                                        </th>
                                        <th scope="row" className="px-6 py-4 font-medium text-gray-500 whitespace-nowrap ">
                                          {element && element.status === 1 &&
                                            <span className="relative ">
                                              <button className="px-4 py-2 font-medium tracking-wider text-white bg-green-900 border rounded-md shadow-sm">Approved</button>
                                            </span>
                                          }
                                          {element && element.status === 2 &&
                                            <span className="relative ">
                                              <button className="px-4 py-2 font-medium tracking-wider text-white bg-red-900 border rounded-md shadow-sm">Rejected</button>
                                            </span>
                                          }


                                          {/* {element && element.status === 0&&
                                            <>
                                              <span className="relative ">
                                          <button className="px-4 py-2 font-medium tracking-wider text-white bg-green-700 border rounded-md shadow-sm hover:shadow-lg hover:bg-green-300" onClick={() => deleteGame(element,1)}>Approve</button>
                                        </span>
                                        <span className="relative ">
                                          <button className="px-4 py-2 font-medium tracking-wider text-white bg-red-500 border rounded-md shadow-sm hover:shadow-lg hover:bg-red-300" onClick={() => deleteGame(element,2)}>Reject</button>
                                        </span>
                                            </>
                                           } */}

                                        </th>
                                        {/* <td className="px-6 py-4 text-right">
                                      
                                      </td> */}
                                      </tr>
                                    </React.Fragment>
                                  )
                                }
                              })
                                : null
                              }
                            </tbody>
                          </table>
                          {
                            isMobile ?
                              <nav className="relative z-0 flex justify-end mt-5 w-76">
                                {
                                  totalPayment && totalPayment > 10 ?
                                    <ReactPaginate
                                      previousLabel={'Prev'}
                                      nextLabel={'Next'}
                                      breakLabel={'...'}
                                      breakClassName={'break-me'}
                                      pageCount={totalPayment / size}
                                      marginPagesDisplayed={1}
                                      pageRangeDisplayed={1}
                                      onPageChange={handlePageClick}
                                      containerClassName={'pagination'}
                                      pageClassName={'page-cls'}
                                      activeClassName={'active'}
                                      forcePage={currentPage}
                                    />
                                    : null}
                              </nav> : <nav className="relative z-0 flex justify-end mt-5 w-76">
                                {
                                  totalPayment && totalPayment > 10 ?
                                    <ReactPaginate
                                      previousLabel={'Previous'}
                                      nextLabel={'Next'}
                                      breakLabel={'...'}
                                      breakClassName={'break-me'}
                                      pageCount={totalPayment / size}
                                      marginPagesDisplayed={3}
                                      pageRangeDisplayed={3}
                                      onPageChange={handlePageClick}
                                      containerClassName={'pagination'}
                                      pageClassName={'page-cls'}
                                      activeClassName={'active'}
                                      forcePage={currentPage}
                                    />
                                    : null}
                              </nav>
                          }
                        </div>

                      </div>
                    </div>
                  </div>

                  <ViewGameModal
                    rowData={rowData}
                    viewModal={viewModal}
                    handleViewHideModal={handleViewHideModal}
                  />


                </main>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddFundReport;