import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import DashboardJSON from "./WalletManagement.json";
import { gamesActions, marketActions, walletActions } from "../../_actions";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import ReactPaginate from "react-paginate";
import { isMobile } from "react-device-detect";
import Loader from "../../components/Loader/Loader";
import ViewGameModal from "../gameManagement/components/ViewGameModal/ViewGameModal";
import Dropdown from "./DropDown";
import Select from "react-select";
import moment from "moment/moment";
import { IoMdArrowRoundBack } from "react-icons/io";
const AddFundReport = () => {
  const dispatch = useDispatch();
  const selector = useSelector((state) => state);
  let [viewModal, setViewModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const today = new Date().toISOString().split("T")[0];
  const [fieldsUpdate, setFieldsUpdate] = useState({});
  const [errorsUpdate, setErrorsUpdate] = useState({});
  const [fieldsGame, setFieldsGame] = useState({});
  const [errorsGame, setErrorsGame] = useState({});
  const [size, setSize] = useState(50);
  const [offset, setOffset] = useState(0);
  const [pageNo, setPageNo] = useState(1);
  const [keyWord, setkeyWord] = useState("");
  const [fromDate, setfromDate] = useState("");
  const [toDate, settoDate] = useState("");
  const [number, setNumber] = useState("");
  const [dayOfWeek, setDayOfWeek] = useState('');
  const [monthName, setMonthName] = useState('');

  const { paymentList, totalPayment } = useSelector((state) => state.wallet);
  const { allMarket } = useSelector((state) => state.markets);
  const { gamesList, gamesTotal } = useSelector((state) => state.games);



  const [rawDate, setRawDate] = useState('');
  const [formattedDate, setFormattedDate] = useState('');
  const dateInputRef = useRef(null);

  useEffect(() => {
    let gameReq = {
      fromDate: fromDate,
      toDate: toDate,
      keyWord: keyWord,
      sortBy: "createdAt", // or updatedAt, depending on your data schema
      sortOrder: "desc",
      pageNo: 1,
      size: 500,
    };
    let listReq = {
      fromDate: fromDate,
      toDate: toDate,
      keyWord: keyWord,
      sortBy: "",
      sortOrder: "",
      pageNo: pageNo,
      size: size,
    };

    dispatch(gamesActions.getResultListAdmin(listReq));
    // dispatch(gamesActions.marketNumberInSingle());
    dispatch(gamesActions.getAllGames(gameReq));
    // dispatch(gamesActions.marketNumberThreeDigit());
  }, [pageNo]);

  // const inputChangeCreate = (e) => {
  //   // e.preventDefault();
  //   const dateValue = e.target.value;
  //   const { name, value } = e.target;
  //   setFieldsGame((prevState) => ({ ...prevState, [name]: value }));
  //   setErrorsGame((prevState) => ({ ...prevState, [name]: "" }));
  // };












  const inputChangeCreate = (e) => {
    const { name, value } = e.target;

    if (name === "date") {
      const format = "dddd - DD-MMMM"; // Desired format: "Wednesday - 28-August"
      const formattedDate = moment(value, "YYYY-MM-DD").format(format);

      setFieldsGame((prevState) => ({
        ...prevState,
        [name]: formattedDate,
      }));
    } else {
      setFieldsGame((prevState) => ({ ...prevState, [name]: value }));
    }

    console.log("onChange triggered", fieldsGame['date']);
    setErrorsGame((prevState) => ({ ...prevState, [name]: "" }));
  };


  const createGameSubmit = (e) => {
    e.preventDefault();
    if (handleValidationCreateGame()) {
      let gamePageRefresh = {
        fromDate: fromDate,
        toDate: toDate,
        keyWord: keyWord,
        sortBy: "",
        sortOrder: "",
        pageNo: pageNo,
        size: size,
      };

      let obj = {
        gameId: fieldsGame?.gameId,
        betNumber: number,
        isOpen: fieldsGame.isOpen == "true" ? true : false,
        date: rawDate,
      };

      dispatch(
        gamesActions.saveResultAdmin(obj, gamePageRefresh, ShowWinnerList2)
      );
      // setFieldsGame({})
    }
  };

  const handleValidationUpdateGame = () => {
    let formIsValid = true;
    let errors = {};

    if (!fieldsUpdate.name || fieldsUpdate.name === "") {
      formIsValid = false;
      errors.name = DashboardJSON.ErrorMsg.name;
    }

    if (!fieldsUpdate.shortName || fieldsUpdate.shortName === "") {
      formIsValid = false;
      errors.shortName = DashboardJSON.ErrorMsg.shortName;
    }

    if (!fieldsUpdate.openTime || fieldsUpdate.openTime === "") {
      formIsValid = false;
      errors.openTime = DashboardJSON.ErrorMsg.openTime;
    }

    if (!fieldsUpdate.closingTime || fieldsUpdate.closingTime === "") {
      formIsValid = false;
      errors.closingTime = DashboardJSON.ErrorMsg.closingTime;
    }

    if (!fieldsUpdate.priority || fieldsUpdate.priority === "") {
      formIsValid = false;
      errors.priority = DashboardJSON.ErrorMsg.priority;
    }

    if (!fieldsUpdate.backgroundColor || fieldsUpdate.backgroundColor === "") {
      formIsValid = false;
      errors.backgroundColor = DashboardJSON.ErrorMsg.backgroundColor;
    }
    console.log("errorsUpdate????", errorsUpdate);
    setErrorsUpdate(errors);
    return formIsValid;
  };

  const handleValidationCreateGame = () => {
    let formIsValid = true;
    let errors = {};

    if (!rawDate || rawDate === "") {
      formIsValid = false;
      errors.date = "Date is required";
    }

    if (!fieldsGame.gameId || fieldsGame.gameId === "") {
      formIsValid = false;
      errors.gameId = "Game is Required";
    }

    if (!fieldsGame.isOpen || fieldsGame.isOpen === "") {
      formIsValid = false;
      errors.isOpen = "Select Sessions";
    }

    if (!number || number === "" ||number.length!==3) {
      formIsValid = false;
      errors.num = "Empty fields";
    }

    setErrorsGame(errors);
    return formIsValid;
  };

  const handlePageClick = (data) => {
    console.log("data_page__page__page__page__page_???", data);
    let offset = Math.ceil(data.selected * size);
    setCurrentPage(data.selected);
    setOffset(offset);
    setPageNo(data.selected + 1);
    let pageReq = {
      fromDate: fromDate,
      toDate: toDate,
      keyWord: keyWord,
      sortBy: "",
      sortOrder: "",
      pageNo: data.selected + 1,
      size: size,
    };
    dispatch(gamesActions.getAllGames(pageReq));
  };

  const ShowWinnerList = () => {
    let obj = {
      gameId: fieldsGame?.gameId,
      // betNumber: fieldsGame?.num?.trim(),
      betNumber: number,
      isOpen: fieldsGame.isOpen == "true" ? true : false,
      date: rawDate,
    };
    if (handleValidationCreateGame()) {
      dispatch(gamesActions.getWinnerListByBetNumber(obj));
      setViewModal(true);
    }
  };
  const ShowWinnerList2 = () => {
    let obj = {
      gameId: fieldsGame?.gameId,
      betNumber: number,
      isOpen: fieldsGame.isOpen == "true" ? true : false,
      date: rawDate,
    };
    if (handleValidationCreateGame()) {
      dispatch(gamesActions.resultDeclare(obj));
    }
  };

  let handleViewHideModal = () => {
    setViewModal(false);
  };

  const rollback = (e) => {
    let obj = {
      gameId: e?.gameId?._id,
      closeNumber: e?.closeNumber == 0 ? "000" : e?.openNumber,
      jodiNumber: e?.jodiNumber,
      openNumber: e?.openNumber == 0 ? "000" : e?.openNumber,
      date: e && e.rDate && new Date(e.rDate).toISOString().split("T")[0],
    };

    let listReq = {
      fromDate: fromDate,
      toDate: toDate,
      keyWord: keyWord,
      sortBy: "",
      sortOrder: "",
      pageNo: pageNo,
      size: size,
      // "brandGame":false,
    };
    confirmAlert({
      title: "Confirm to Delete",
      message: "Are you sure you want to Rollback",
      buttons: [
        {
          label: "Yes",
          onClick: () =>
            dispatch(gamesActions.rollbackResultDeclare(obj, listReq)),
        },
        {
          label: "No",
        },
      ],
    });
  };

  let { games } = selector ? selector : {};
  let {
    loading,
    gameResultList,
    gameResultTotal,
    winnerList,
    resultList,
    numberArray,
    numberList,
  } = games ? games : {};
  console.log(gameResultList)

  let handleNumber = (e) => {
    e.preventDefault();
    
    if (e.target.value.toString().length > 3) {
      return;
    } else {
      setNumber(e.target.value);
    }
  };


  const handleDateChange = (event) => {
    const dateValue = event.target.value;
    setRawDate(dateValue);


    const formattedDate = moment(dateValue).format('dddd-MMM-YYYY');
    setFormattedDate(formattedDate);
  };

  const handleTextClick = () => {
    dateInputRef.current.focus(); // Focus on the hidden date input to open the date picker
  };

const handleBack=()=>{
  window.history.back()
}

  return (
    <>
      <Loader loading={loading} />
      <div className="z-0 overflow-hidden overflow-y-auto focus:outline-none">
        <div className="relative">
          <div className="relative z-20 flex min-h-screen">
            <div className="flex w-full overflow-hidden ">
              <div className="flex flex-col flex-1 overflow-hidden">
                <main className="relative flex-1 ">
                  <div className="p-4">
                    <div className="mx-auto max-w-screen-3xl space-y-5">
                      <div className="text-left bg-white space-y-2 rounded-lg">
                        <div className="px-6 py-4 flex items-center justify-start gap-3 border-b">
                        <IoMdArrowRoundBack className='text-2xl cursor-pointer hover:scale-150 duration-300' onClick={handleBack} />
                          <span className=" text-lg font-bold">
                            Declare Result
                          </span>
                        </div>
                        <div className="px-6 py-4 w-full">
                          <div className="block  md:flex gap-4 ">
                            <div className="w-auto">
                              <label
                                class="block text-gray-700 text-base mb-2 font-bold"
                                for="gamename"
                              >
                                Result Date
                              </label>
                              {/* <input
                                className={`w-full px-3 py-2 text-sm bg-white border border-gray-400 rounded-md focus:outline-none uppercase`}
                                id="date"
                                name="date"
                                placeholder="Enter Date"
                                value={
                                  fieldsGame && fieldsGame["date"]
                                    ? fieldsGame["date"]
                                    : ""
                                }
                                max={today}
                                type="date"
                                data-date="" data-date-format="DD MMMM YYYY"
                                onChange={inputChangeCreate}
                              /> */}

                              {/* <input
                                className="w-full px-3 py-2 text-sm bg-white border border-gray-400 rounded-md focus:outline-none uppercase"
                                id="date"
                                name="date"
                                placeholder="Enter Date"
                                value={moment(fieldsGame["date"], "dddd - DD-MMMM").format("YYYY-MM-DD") || ""}
                                max={today}
                                type="date"
                                onChange={inputChangeCreate}
                              /> */}
                              <div className="w-full px-3 py-[10px] flex capitalize text-sm bg-white border border-gray-400 rounded-md focus:outline-none">
                                <input
                                  type="text"
                                  value={formattedDate}
                                  readOnly
                                  onClick={handleTextClick}
                                  className=" "
                                />
                                <input
                                  id="date-input"
                                  type="date"
                                  max={today}
                                  ref={dateInputRef}
                                  onChange={handleDateChange}
                                  value={rawDate}
                                  className="outline-none w-24"
                                />
                              </div>
                              {errorsGame && errorsGame["date"] ? (
                                <div className="text-red-600 invalid-feedback">
                                  {errorsGame["date"]}
                                </div>
                              ) : null}
                            </div>

                            <div className="w-full mt-1 shadow-sm">
                              <label
                                class="block text-gray-700 text-base mb-2 whitespace-nowrap font-bold"
                                for="name"
                              >
                                Game Name
                              </label>
                              <select
                                className={`w-full px-3 py-[10px] capitalize text-sm bg-white border border-gray-400 rounded-md focus:outline-none`}
                                id="gameId"
                                name="gameId"
                                placeholder="name"
                                value={
                                  fieldsGame && fieldsGame["gameId"]
                                    ? fieldsGame["gameId"]
                                    : ""
                                }
                                type="text"
                                onChange={inputChangeCreate}
                              >
                                <option value="">Select Game</option>
                                {gamesList && gamesList.length > 0
                                  ? gamesList.map((element, index) => (
                                    <option
                                      value={
                                        element && element._id
                                          ? element._id
                                          : ""
                                      }
                                    >
                                      {element && element.name
                                        ? element.name
                                        : ""}
                                    </option>
                                  ))
                                  : null}
                              </select>
                              {errorsGame && errorsGame["gameId"] ? (
                                <div className="text-red-600 invalid-feedback">
                                  {errorsGame["gameId"]}
                                </div>
                              ) : null}
                            </div>

                            <div className=" mt-1 shadow-sm">
                              <label class="block text-gray-700 text-base font-bold mb-2">
                                Session
                              </label>
                              <select
                                onChange={inputChangeCreate}
                                name="isOpen"
                                value={
                                  fieldsGame && fieldsGame["isOpen"]
                                    ? fieldsGame["isOpen"]
                                    : ""
                                }
                                className="px-3 py-[10px] text-sm bg-transparent border border-gray-900 rounded md:w-80 w-full focus:outline-none text-[#495057] placeholder:text-gray-500 block"
                              >
                                <option value="">Select Session</option>
                                <option value={false}>Close</option>
                                <option value={true}>Open</option>
                              </select>
                              {errorsGame && errorsGame["isOpen"] ? (
                                <div className="text-red-600 invalid-feedback">
                                  {errorsGame["isOpen"]}
                                </div>
                              ) : null}
                            </div>

                            {/* <div className=" mt-1 w-full shadow-sm">
                              <label class="block text-gray-700 text-base font-bold mb-2">
                                Number
                              </label>
                              <select
                                onChange={inputChangeCreate}
                                name="num"
                                value={
                                  fieldsGame && fieldsGame["num"]
                                    ? fieldsGame["num"]
                                    : ""
                                }
                                className="px-3 py-[10px] text-sm bg-transparent border border-gray-900 rounded md:w-60 w-full focus:outline-none text-[#495057] placeholder:text-gray-500 block"
                              >
                                <option value="">Select Number</option>
                                {singleArray &&
                                  singleArray.length > 0 &&
                                  singleArray.map((option) => (
                                    <option key={option} value={option}>
                                      {option}
                                    </option>
                                  ))}
                              </select>
                              {errorsGame && errorsGame["num"] ? (
                                <div className="text-red-600 invalid-feedback">
                                  {errorsGame["num"]}
                                </div>
                              ) : null}
                            </div> */}

                            <div className="mt-1 w-full ">
                              <label className="block text-gray-700 text-base font-bold mb-2">
                                Number
                              </label>
                              <input
                                value={number}
                                onChange={(e) => handleNumber(e)}
                                placeholder="Enter Number"
                                className="px-3 py-[10px]  text-sm bg-transparent border border-gray-900 rounded  w-full focus:outline-none text-[#495057] placeholder:text-gray-500 block"
                              />
                              {/* <Select
                                onChange={(selectedOption) =>
                                  inputChangeCreate({
                                    target: {
                                      name: "num",
                                      value: selectedOption.value,
                                    },
                                  })
                                }
                                name="num"
                                value={
                                  fieldsGame && fieldsGame["num"]
                                    ? {
                                        value: fieldsGame["num"],
                                        label: fieldsGame["num"],
                                      }
                                    : ""
                                }
                                options={
                                  singleArray &&
                                  singleArray.length > 0 &&
                                  singleArray.map((option) => ({
                                    value: option,
                                    label: option,
                                  }))
                                }
                                classNamePrefix="react-select"
                                placeholder="Select Number"
                                className="md:w-60 w-full"
                              /> */}
                              {errorsGame && errorsGame["num"] ? (
                                <div className="text-red-600 invalid-feedback">
                                  {errorsGame["num"]}
                                </div>
                              ) : null}
                            </div>
                            <div className="flex justify-center md:flex-row flex-col md:justify-end  mt-2 w-full  items-end gap-3 ">
                            <button
                              className="flex  justify-center md:w-32 w-full px-4 py-2 text-sm text-white transition duration-150 ease-in-out bg-[#7D1970] border border-[#7D1970] rounded-full whitespace-nowrap  focus:border-[#7D1970] focus:shadow-outline-yellow active:bg-[#7D1970]"
                              type="button"
                              onClick={(e) => createGameSubmit(e)}
                            >
                              Save
                            </button>

                            {/* <button
                              className="flex  justify-center md:w-32 w-full px-4 py-2 text-sm text-white transition duration-150 ease-in-out bg-yellow-600 border border-yellow-600 rounded-full whitespace-nowrap  focus:border-yellow-600 focus:shadow-outline-yellow active:bg-yellow-600"
                              type="button"
                              onClick={(e) => ShowWinnerList(e)}
                            >
                              Show Winner List
                            </button> */}

                            
                            {/* <button
                              className="flex  justify-center md:w-32 w-full px-4 py-2 text-sm text-white transition duration-150 ease-in-out bg-pink-600 border border-pink-600 rounded-full whitespace-nowrap  focus:border-pink-600 focus:shadow-outline-pink active:bg-pink-600"
                              type="button"
                              onClick={(e) => ShowWinnerList2(e)}
                            >
                              Declare Result
                            </button> */}
                          </div>
                          </div>

                         
                        </div>
                      </div>
                      <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
                        <table className="w-full text-sm text-left rtl:text-right text-gray-500 ">
                          <thead className="text-gray-800 capitalize bg-gray-50  ">
                            <tr>
                              <th scope="col" className="px-6 py-3 text-center">
                                S.No.
                              </th>
                              <th scope="col" className="px-6 py-3 text-center">
                                Result Date
                              </th>
                              <th scope="col" className="px-6 py-3 text-center">
                                Game Name
                              </th>
                              <th scope="col" className="px-6 py-3 text-center">
                                Opening Number
                              </th>
                              <th scope="col" className="px-6 py-3 text-center">
                                Jodi Number
                              </th>
                              <th scope="col" className="px-6 py-3 text-center">
                                Closing Number
                              </th>
                              <th scope="col" className="px-6 py-3 text-center">
                                Rollback
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {gameResultList && gameResultList.length > 0 ? (
                              gameResultList?.sort((a, b) => b.rDate - a.rDate)?.map((element, index) => (
                                <React.Fragment key={index}>
                                  <tr
                                    key={index}
                                    className={
                                      index % 2 === 0
                                        ? "bg-white border-b  "
                                        : "bg-gray-100  "
                                    }
                                  >
                                    <td
                                      scope="row"
                                      className="px-6 py-4 font-medium text-gray-700 whitespace-nowrap  text-center "
                                    >
                                      {offset + index + 1}
                                    </td>
                                    <td
                                      scope="row"
                                      className="px-6 py-4 font-medium text-gray-700 whitespace-nowrap  text-center "
                                    >
                                      {element && element.rDate
                                        ? new Date(
                                          element.rDate
                                        ).toLocaleDateString()
                                        : "-"}
                                    </td>
                                    <td
                                      scope="row"
                                      className="px-6 py-4 font-medium text-gray-700 capitalize whitespace-nowrap  text-center "
                                    >
                                      {element && element.gameId
                                        ? element.gameId?.name
                                        : "-"}
                                    </td>
                                    <td
                                      scope="row"
                                      className="px-6 py-4 font-medium text-gray-700 whitespace-nowrap  text-center "
                                    >
                                      {element && element.openNumber?.toString()
                                        ? element.openNumber?.toString()
                                        : "-"}
                                    </td>
                                    <td
                                      scope="row"
                                      className="px-6 py-4 font-medium text-gray-700 whitespace-nowrap  text-center "
                                    >
                                      {element && element.jodiNumber?.toString()
                                        ? element.jodiNumber?.toString()
                                        : "-"}
                                    </td>
                                    <td
                                      scope="row"
                                      className="px-6 py-4 font-medium text-gray-700 whitespace-nowrap  text-center "
                                    >
                                      {element &&
                                        element.closeNumber?.toString()
                                        ? element.closeNumber?.toString()
                                        : "-"}
                                    </td>
                                    <td
                                      scope="row"
                                      className="px-6 py-4 font-medium text-gray-700 whitespace-nowrap  text-center "
                                    >
                                      <span
                                        className=" text-red-500 cursor-pointer"
                                        onClick={() => rollback(element)}
                                      >
                                        RoleBack
                                      </span>

                                      {/* {element.jodiNumber &&
                                        element.jodiNumber &&
                                        element.jodiNumber.length == 2 ? (
                                          <span
                                            className=" text-red-500 cursor-pointer"
                                            onClick={() => rollback(element)}
                                          >
                                            RoleBack
                                          </span>
                                        ) : (
                                          "-"
                                        )} */}
                                    </td>
                                  </tr>
                                </React.Fragment>
                              ))
                            ) : (
                              <div className="">No Data</div>
                            )}
                          </tbody>
                        </table>
                      </div>
                      {gameResultList && gameResultTotal > size ? (
                        <ReactPaginate
                          previousLabel={"Prev"}
                          nextLabel={"Next"}
                          breakLabel={"..."}
                          breakClassName={"break-me"}
                          pageCount={Math.ceil(gameResultTotal / size)}
                          marginPagesDisplayed={1}
                          pageRangeDisplayed={1}
                          onPageChange={handlePageClick}
                          containerClassName={"pagination"}
                          pageClassName={"page-cls"}
                          activeClassName={"active"}
                          forcePage={currentPage}
                        />
                      ) : null}
                    </div>
                  </div>
                </main>
              </div>
            </div>
          </div>
          <ViewGameModal
            viewModal={viewModal}
            handleViewHideModal={handleViewHideModal}
            winnerList={winnerList}
            ShowWinnerList2={ShowWinnerList2}
          />
        </div>
      </div>
    </>
  );
};

export default AddFundReport;
