import { bankConstants } from '../_constants';

export default function bankReducer(state = {}, action) {
  switch (action.type) {
    

    case bankConstants.CHART_REQUEST:
      return {
        ...state,
        loading: true
      };
    case bankConstants.CHART_SUCCESS:
      return {
        ...state,
        loading: false,
        chartdata:action?.report?.data?.finaData

      };
    case bankConstants.CHART_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case bankConstants.DELETE_NOTIFICATION_REQUEST:
      return {
        ...state,
        loading: true
      };
    case bankConstants.DELETE_NOTIFICATION_SUCCESS:
      return {
        ...state,
        loading: false,

      };
    case bankConstants.DELETE_NOTIFICATION_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case bankConstants.GETNOTIFICATION_LIST_REQUEST:
      return {
        ...state,
        loading: true
      };
    case bankConstants.GETNOTIFICATION_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        notificationTotal:action?.report?.data?.total,
        notificationList:action?.report?.data?.list
      };
    case bankConstants.GETNOTIFICATION_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case bankConstants.CREATE_NOTIFICATION_ADMIN_REQUEST:
      return {
        ...state,
        loading: true
      };
    case bankConstants.CREATE_NOTIFICATION_ADMIN_SUCCESS:
      return {
        ...state,
        loading: false,
        notificationdata:action
      };
    case bankConstants.CREATE_NOTIFICATION_ADMIN_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case bankConstants.UPDATE_BANK_DETAILS_ADMIN_REQUEST:
      return {
        ...state,
        loading: true
      };
    case bankConstants.UPDATE_BANK_DETAILS_ADMIN_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case bankConstants.UPDATE_BANK_DETAILS_ADMIN_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    
    
    
    case bankConstants.ADD_ADMIN_BANK_DETAIL_REQUEST:
      return {
        ...state,
        loading: true
      };
    case bankConstants.ADD_ADMIN_BANK_DETAIL_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case bankConstants.ADD_ADMIN_BANK_DETAIL_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    
    case bankConstants.GET_ADMIN_BANK_REQUEST:
      return {
        ...state,
        loading: true
      };
    case bankConstants.GET_ADMIN_BANK_SUCCESS:
      return {
        ...state,
        adminBankDetail: action?.report?.data,
        loading: false,
      };
    case bankConstants.GET_ADMIN_BANK_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };


    case bankConstants.GET_BANK_DETAILS_BY_ADMIN_REQUEST:
      return {
        ...state,
        loading: true
      };
    case bankConstants.GET_BANK_DETAILS_BY_ADMIN_SUCCESS:
      return {
        ...state,
        bankDetailsByAdmin: action?.report?.data,
        loading: false,
      };
    case bankConstants.GET_BANK_DETAILS_BY_ADMIN_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };





      case bankConstants.GET_CLIENT_PAYMENTS_LIST_REQUEST:
        return {
          ...state,
          loading: true
        };
      case bankConstants.GET_CLIENT_PAYMENTS_LIST_SUCCESS:
        return {
          ...state,
          paymentList: action?.report?.data,
          loading: false,
        };
      case bankConstants.GET_CLIENT_PAYMENTS_LIST_FAILURE:
        return {
          ...state,
          loading: false,
          error: action.error
        };

























    default:
      return state
  }
}