import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { GiAmericanFootballPlayer } from "react-icons/gi";
import { Link, useParams } from 'react-router-dom';
import { FaUser, FaFileDownload } from "react-icons/fa";
import { FaLock } from "react-icons/fa6";
import { GrEdit } from "react-icons/gr";
import { gamesActions, marketActions, walletActions, userActions } from '../../_actions';
import { isMobile } from "react-device-detect";
import { TfiEye } from "react-icons/tfi";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import Loader from '../../components/Loader/Loader';
import ViewGameModal from './ViewGameModal';
import ReactPaginate from 'react-paginate';
import { IoGameController } from "react-icons/io5";
import { FaUserSecret } from "react-icons/fa6";
import ChangePassword from './ChangePasswordModel';
import ChangeTnxPasswordModel from './ChangeTnxPasswordModel';
import { PiHandWithdraw } from "react-icons/pi";
import { PiHandDeposit } from "react-icons/pi";

const Dashboard = () => {
  const selector = useSelector(state => state)
  const dispatch = useDispatch();
  const [sessiondata, setsessiondata] = useState({})
  const [showModal, setShowModal] = useState(false);
  const [showModal2, setShowModal2] = useState(false);
  const { gamesList, gamesTotal } = useSelector(state => state.games);


  const { paymentList, totalPayment } = useSelector(state => state.wallet);
  const { adminProfileDetails } = useSelector(state => state.users);
  let [rowData, setRowData] = useState(null);
  let [viewModal, setViewModal] = useState(false);
  const [fieldsGame, setFieldsGame] = useState({});
  const [errorsGame, setErrorsGame] = useState({});
  const [size, setSize] = useState(10);
  const [offset, setOffset] = useState(0);
  const [pageNo, setPageNo] = useState(1);
  const [currentPage, setCurrentPage] = useState(0);
  const [gameIds, setgameIds] = useState('');
  const [marketIds, setmarketIds] = useState('');
  const [fromDates, setfromDates] = useState('');
  useEffect(() => {
    let sessionData = window.localStorage.getItem('adminuser')
    let dataaa = JSON.parse(sessionData)


    setsessiondata()
    let gameReq = {
      "fromDate": "",
      "toDate": "",
      "keyWord": "",
      "sortBy": "",
      "sortOrder": "",
      "pageNo": pageNo,
      "size": 1000
    }
    let gameReq22 = {
      "fromDate": "",
      "toDate": "",
      "keyWord": "",
      "sortBy": "",
      "sortOrder": "",
      "type": "",
      "pageNo": 1,
      "size": 2000
    }

    dispatch(gamesActions.getAllGames(gameReq));
    dispatch(userActions.getAdminProfile());
    dispatch(walletActions.getPaymentListForAdmin(gameReq22));


    dispatch(userActions.getTotalCount());
    dispatch(gamesActions.getAllMarket())

    let obj = {

      "gameId": gameIds,
      "marketId": marketIds,
      "fromDate": fromDates
    }


    dispatch(walletActions.getBetsTotalCount(obj))

  }, []);

  const inputChangeCreate = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setFieldsGame(prevState => ({ ...prevState, [name]: value }));
    setErrorsGame(prevState => ({ ...prevState, [name]: "" }));
  };

  const createGameSubmit = (e) => {
    e.preventDefault();

    let obj = {
      "gameId": gameIds || "",
      "marketId": marketIds || "",
      "fromDate": fromDates || ""
    }
    dispatch(walletActions.getBetsTotalCount(obj))



  };

  const deleteGame2 = (data, elem) => {
    console.log(data, elem)
    let gameIdReq = {
      "paymentId": data._id,
      "status": elem
    }

    let gamePageRefresh = {
      "fromDate": "",
      "toDate": "",
      "keyWord": "",
      "sortBy": "",
      "sortOrder": "",
      "type": "DEPOSIT",
      "pageNo": pageNo,
      "size": size
    }

    confirmAlert({

      title: 'Confirm to Change Status?',
      message: `Are you sure you want to change Status of ${data?.userId?.userName}?`,
      buttons: [
        {
          label: 'Yes',
          onClick: () => dispatch(gamesActions.approvePaymentByAdmin(gameIdReq, gamePageRefresh))
        },
        {
          label: 'No'
        }
      ]
    });
    setViewModal(false)
  }

  const handleValidationCreateGame = () => {
    let formIsValid = true;
    let errors = {};

    if (!fieldsGame.userId || fieldsGame.userId === "") {
      formIsValid = false;
      errors.userId = "Select User";
    }

    if (!fieldsGame.amount || fieldsGame.amount === "") {
      formIsValid = false;
      errors.amount = "Enter amount";
    }

    setErrorsGame(errors);
    return formIsValid;
  };

  const deleteGame = (data, elem) => {
    let gameIdReq = {
      "paymentId": data._id,
      "status": elem
    }

    let gamePageRefresh = {
      "fromDate": "",
      "toDate": "",
      "keyWord": "",
      "sortBy": "",
      "sortOrder": "",
      "type": "DEPOSIT",
      "pageNo": pageNo,
      "size": size
    }

    confirmAlert({

      title: elem == 1 ? 'Confirm to Approve?' : 'Confirm to Reject?',
      message: elem == 1 ? `Are you sure you want to Approve Deposit Fund of ${data?.userId?.userName}?` : `Are you sure you want to Reject Deposit Fund of ${data?.userId?.userName}?`,
      buttons: [
        {
          label: 'Yes',
          onClick: () => dispatch(gamesActions.approvePaymentByAdmin(gameIdReq, gamePageRefresh))
        },
        {
          label: 'No'
        }
      ]
    });
  }


  const handlePageClick = (data) => {

    let offset = Math.ceil(data.selected * size);
    setOffset(offset);
    setPageNo(data.selected + 1);
    setCurrentPage(data.selected)
    let pageReq = {
      "type": "DEPOSIT",
      "fromDate": "",
      "toDate": "",
      "keyWord": "",
      "sortBy": "",
      "sortOrder": "",
      "pageNo": data.selected + 1,
      "size": size
    }
    dispatch(walletActions.getPaymentListForAdmin(pageReq));
  }
  const handleViewOpenModal = (data) => {
    // console.log(data)
    setViewModal(true);
    // console.log(data)
    setRowData(data);
  }

  const handleViewHideModal = (data) => {
    setViewModal(false);
    setRowData({});
  }

  let { users, wallet } = selector ? selector : {}

  let { games } = selector ? selector : {};
  let { allMarket, allUserList } = games ? games : {};
  let { totalCountBet } = wallet ? wallet : {};
  let { totalCounts } = users ? users : {};
  let { loading } = users ? users : {};
  console.log("paymentList, totalPayment ", paymentList, totalPayment)
  let depostiCount = paymentList && paymentList.length > 0 && paymentList.filter(e => e?.type == "DEPOSIT").length

  let withdrawlCount = paymentList && paymentList.length > 0 && paymentList.filter(e => e?.type == "WITHDRAW").length

  const today = new Date().toISOString().split('T')[0];
  return (
    <>
      <Loader loading={loading} />
      <Loader loading={selector?.wallet?.loading} />
      <div className="z-0 overflow-hidden overflow-y-auto focus:outline-none">
        <div className="relative">
          <div className="relative z-20 flex min-h-screen">
            <div className="flex w-full overflow-hidden ">
              <div className="flex flex-col flex-1 overflow-hidden">
                <main className="relative flex-1 ">
                  <div className="p-3  sm:p-5">
                    <div className="mx-auto max-w-screen-3xl space-y-3">
                      <div className='w-full grid grid-cols-1 lg:grid-cols-3 gap-3'>
                        <div className='w-full h-full'>
                          <div className='w-full bg-white rounded-xl'>
                            <div className='bg-[#F64401] rounded-t-xl p-4 pb-8 flex  justify-between'>
                              <div className=' flex flex-col'>
                                <span className='text-white font-semibold'>Welcome Back!</span>
                                <span className='text-white'>{adminProfileDetails && adminProfileDetails.adminData ? adminProfileDetails?.adminData?.userName : ""} Dashboard</span>
                              </div>
                              {/* <div  className='flex justify-center px-1 rounded-lg h-8 text-sm shadow-2xl  items-center text-[#F64401] bg-[white]'>Change Password</div> */}

                            </div>
                            <div className='w-full p-4 pb-16 flex justify-between items-start'>
                              <div className='relative w-full flex flex-col justify-start'>
                                <div className='absolute -top-8 bg-white md:w-28 md:h-28 w-20 h-20 p-1 rounded-full'>
                                  <img src='/Img/profile.png' className='rounded-full w-full h-full' alt='logo' />
                                </div>
                              </div>

                              <div className=' flex gap-3   text-lg flex-col '>

                                <span className=' whitespace-nowrap'> {totalCounts?.data?.totalActiveUsers || 0} Active Users</span>
                                <span className=' whitespace-nowrap'>{totalCounts?.data?.totalDeactiveUsers || 0} Deactive Users</span>

                              </div>

                            </div>
                            <div className='p-4 flex flex-col justify-start'>
                              {/* <span className='text-black font-semibold'>Taj777</span> */}

                            </div>
                          </div>
                        </div>
                        <div className='w-full lg:col-span-2  space-y-3'>
                          <div className='grid lg:grid-cols-3 gap-3'>
                            <Link to="/app/user" className='w-full bg-white flex justify-between items-center rounded-xl p-5'>
                              <div className='w-full flex flex-col justify-center items-start'>
                                <span>Total Users</span>
                                <span className='font-semibold text-gray-600'>{totalCounts?.data?.totalUserCount || 0}</span>
                              </div>
                              <div className='bg-[#F64401] w-12 h-12 flex justify-center items-center p-4 rounded-full text-white'><FaUser /></div>
                            </Link>
                            <Link to="/app/game" className='w-full bg-white flex justify-between items-center rounded-xl p-5'>
                              <div className='w-full flex flex-col justify-center items-start'>
                                <span>Games</span>
                                <span className='font-semibold text-gray-600'>{totalCounts?.data?.totalGameCounts || 0}</span>
                              </div>
                              <div className='bg-[#F64401] w-12 h-12 flex justify-center items-center p-4 rounded-full text-white'><IoGameController /></div>
                            </Link>
                            <Link to="/app/userbidhistory" className='w-full bg-white flex justify-between items-center rounded-xl p-5'>
                              <div className='w-full flex flex-col justify-center items-start'>
                                <span>Players (Today)</span>
                                <span className='font-semibold text-gray-600'>{totalCounts?.data?.todaysPlayerCount || 0}</span>
                              </div>
                              <div className='bg-[#F64401] w-12 h-12 flex justify-center items-center p-4 rounded-full text-white'><FaUserSecret className=' text-2xl' /></div>
                            </Link>

                           





                            <Link to="/app/withdrawRequest" className='  border-red-500 border-2 flex justify-between place-items-center p-4 rounded-lg text-black bg-white' >
                              <div>

                                <p className=''>Total Withdraw Fund Request</p>
                                <span className='font-semibold text-gray-600'>{withdrawlCount || 0}</span>
                              </div>
                              <div className='bg-[#F64401] w-12 h-12 flex justify-center items-center p-4 rounded-full text-white'><PiHandWithdraw className=' text-2xl' /></div>


                            </Link>


                            <Link to="/app/AddFundRequest" className='flex border-green-500 border-2 justify-between place-items-center p-4  rounded-lg text-black bg-white'>
                              <div>

                                <p className=''>Total Add Fund Request</p>
                                <span className='font-semibold text-gray-600'>{depostiCount || 0}</span>
                              </div>

                              <div className='bg-[#F64401] w-12 h-12 flex justify-center items-center p-4 rounded-full text-white'><PiHandDeposit className=' text-2xl' /></div>

                            </Link>


                            <div onClick={() => setShowModal2(true)} className='w-full bg-white flex justify-between items-center rounded-xl p-5'>
                              <div className='  '>Transaction Password</div>
                              <div className='bg-[#F64401] w-12 h-12 flex justify-center items-center p-4 rounded-full text-white'><GrEdit className=' text-2xl' /></div>
                            </div>

                            <div onClick={() => setShowModal(true)} className='w-full cursor-pointer bg-white flex justify-between items-center rounded-xl p-5'>
                              <div className=' '> Login Password</div>
                              <div className='bg-[#F64401] w-12 h-12 flex justify-center items-center p-4 rounded-full text-white'><FaLock className=' text-2xl' /></div>
                            </div>



                          </div>

                        </div>
                      </div>
                      <div className='bg-white rounded-xl'>
                        <div className='flex justify-between items-center px-3 border-b'>
                          <span className="p-5 text-lg text-left text-gray-900 bg-white">
                            Profit / Loss (Report)
                          </span>
                        </div>

                        <div className='p-5 space-y-2'>
                          <div className="flex md:flex-row gap-5 flex-col ">
                            <div className="mt-1 shadow-sm w-full">
                              <label class="block text-gray-700 text-base mb-2" for="name">Date</label>
                              <input className="w-full px-3 py-2 text-sm bg-white border border-gray-400 rounded-xl focus:outline-none"
                                id="fromDate" name="fromDate"
                                value={fromDates} max={today} type="date" onChange={(e) => setfromDates(e.target.value)} />

                            </div>
                            <div className="mt-1 shadow-sm w-full">
                              <label class="block text-gray-700 text-base mb-2" for="name">Game Name</label>
                              <select className="w-full px-3 py-2 text-sm bg-white border border-gray-400 rounded-xl focus:outline-none capitalize"
                                id="userId" name="userId" placeholder="name"
                                value={gameIds} type="text" onChange={(e) => setgameIds(e.target.value)} >
                                <option value="">All Games</option>
                                {gamesList && gamesList.length > 0 ? gamesList.map((element, index) => (
                                  <option value={element && element._id ? element._id : ""}>{element && element.name ? element.name : ""}</option>
                                )) : null}
                              </select>
                              {errorsGame && errorsGame["userId"] ?
                                <div className="text-yellow-600 invalid-feedback">
                                  {errorsGame["userId"]}
                                </div>
                                : null}
                            </div>
                            <div className="mt-1 shadow-sm w-full">
                              <label class="block text-gray-700 text-base mb-2" for="name">Market Name</label>
                              <select className="w-full px-3 py-2 text-sm bg-white border border-gray-400 rounded-xl focus:outline-none"
                                id="userId" name="userId" placeholder="name"
                                value={marketIds} type="text" onChange={(e) => setmarketIds(e.target.value)} >
                                <option value="">All Market</option>
                                {allMarket && allMarket.length > 0 ? allMarket.map((element, index) => (
                                  <option value={element && element._id ? element._id : ""}>{element && element.name ? element.name : ""}</option>
                                )) : null}
                              </select>
                              {errorsGame && errorsGame["userId"] ?
                                <div className="text-yellow-600 invalid-feedback">
                                  {errorsGame["userId"]}
                                </div>
                                : null}
                            </div>
                            <div className="flex justify-start items-end">
                              <button className="px-8 py-2 text-base font-semibold text-white capitalize  bg-[#F64401] border border-[#F64401] rounded-xl" type="button" onClick={createGameSubmit}>Submit</button>
                            </div>
                          </div>
                        </div>
                      </div>


                      <div className="bg-white w-full grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 p-4 rounded-xl shadow-md">
  {/* Total Bets Amount */}
  <div className="flex flex-col justify-between items-center border-2 rounded-lg p-3 border-[#F64401] bg-gradient-to-r from-gray-50 to-gray-100 w-full hover:shadow-lg transition-shadow duration-300">
    <span className="font-semibold text-md text-gray-700">Total Bets Amount</span>
    <span className="font-bold text-lg text-gray-800">{totalCountBet?.data?.totalBets || 0}</span>
  </div>

  {/* Total User Win Amount */}
  <div className="flex flex-col justify-between items-center border-2 rounded-lg p-3 border-[#F64401] bg-gradient-to-r from-gray-50 to-gray-100 w-full hover:shadow-lg transition-shadow duration-300">
    <span className="font-semibold text-md text-gray-700">Total User Win Amount</span>
    <span className="font-bold text-lg text-gray-800">{totalCountBet?.data?.totalWins || 0}</span>
  </div>

  {/* Total Profit/Loss */}
  <div className="flex flex-col justify-between items-center border-2 rounded-lg p-3 border-[#F64401] bg-gradient-to-r from-gray-50 to-gray-100 w-full hover:shadow-lg transition-shadow duration-300">
    <span className="font-semibold text-md text-gray-700">Total Profit/Loss</span>
    <span className={`font-bold text-lg ${Number(totalCountBet?.data?.totalWinAmount) > 0 ? 'text-green-700' : 'text-red-600'}`}>
      {totalCountBet?.data?.totalWinAmount || 0}
    </span>
  </div>
</div>





                      {/* <div className='bg-white rounded-xl'>
                        <div className='flex justify-between bg-[#F64401] rounded-t-md items-center px-3 py-2 border-b'>
                          <span className="p-5 text-lg font-semibold text-left rtl:text-right text-white ">
                            Fund Request
                          </span>
                        </div>
                        <div className="relative overflow-x-auto p-5">
                          <div className='flex justify-between items-center px-3 py-2 border-b'>

                          </div>
                          <table className="w-full text-sm text-left rtl:text-right text-gray-500 ">
                            <thead className="text-gray-900 capitalize bg-gray-50  ">
                              <tr>
                                <th scope="col" className="px-6 py-3">S.No.</th>
                                <th scope="col" className="px-6 py-3">user name</th>
                                <th scope="col" className="px-6 py-3">amount</th>
                     
                                <th scope="col" className="px-6 py-3">Receipt Image</th>
                                <th scope="col" className="px-6 py-3">Date/Time</th>
                                <th scope="col" className="px-6 py-3 flex ">Action</th>
             
                              </tr>
                            </thead>
                            <tbody>
                              {paymentList && paymentList.length > 0 ? paymentList.map((element, index) => {
                                if (element.status === 0) {
                                  return (
                                    <React.Fragment key={index}>
                                      <tr key={index} className={`bg-white border-b border-gray-400`}>
                                        <th scope="row" className="px-6 py-4 font-medium text-gray-500 whitespace-nowrap ">
                                          {offset + index + 1}
                                        </th>
                                        <th scope="row" className="px-6 py-4 font-medium text-gray-500 whitespace-nowrap ">
                                          {element && element.userId ? element?.userId?.userName : "-"}
                                        </th>
                                        <th scope="row" className="px-6 py-4 font-medium text-gray-500 whitespace-nowrap ">
                                          {element && element.amount ? element.amount : "-"}
                                        </th>

                                        <td className="px-6 flex justify-start py-4">
                                          <TfiEye onClick={() => handleViewOpenModal(element)} className=' text-3xl text-[#F64401] cursor-pointer' />

                                        </td>
                                        <th scope="row" className="px-6 py-4 font-medium text-gray-500 whitespace-nowrap ">
                                          {element && element.createdAt ? new Date(element.createdAt).toLocaleString('en-US', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit' }) : "-"}

                                        </th>
                                        <th scope="row" className="px-6 py-4 font-medium flex  text-gray-500 whitespace-nowrap ">


                                          {element && element.status === 0 &&
                                            <>
                                              <div className=''>
                                                <span className="relative ">
                                                  <button className="px-4 py-2 font-medium tracking-wider text-white bg-green-700 border rounded-md shadow-sm hover:shadow-lg hover:bg-green-300" onClick={() => deleteGame(element, 1)}>Approve</button>
                                                </span>
                                                <span className="relative ">
                                                  <button className="px-4 py-2 font-medium tracking-wider text-white bg-red-500 border rounded-md shadow-sm hover:shadow-lg hover:bg-red-300" onClick={() => deleteGame(element, 2)}>Reject</button>
                                                </span>
                                              </div>


                                            </>
                                          }

                                        </th>

                                      </tr>
                                    </React.Fragment>
                                  )
                                }
                              })
                                : <div className=''>
                                  No Data Found
                                </div>
                              }
                            </tbody>
                          </table>
                        </div>
                      </div>
                      {
                        isMobile ?
                          <nav className="relative z-0 flex justify-end mt-5 w-76">
                            {
                              totalPayment && totalPayment > size ?
                                <ReactPaginate
                                  previousLabel={'Prev'}
                                  nextLabel={'Next'}
                                  breakLabel={'...'}
                                  breakClassName={'break-me'}
                                  pageCount={totalPayment / size}
                                  marginPagesDisplayed={1}
                                  pageRangeDisplayed={1}
                                  onPageChange={handlePageClick}
                                  containerClassName={'pagination'}
                                  pageClassName={'page-cls'}
                                  activeClassName={'active'}
                                  forcePage={currentPage}
                                />
                                : null}
                          </nav> : <nav className="relative z-0 flex justify-end mt-5 w-76">
                            {
                              totalPayment && totalPayment > size ?
                                <ReactPaginate
                                  previousLabel={'Previous'}
                                  nextLabel={'Next'}
                                  breakLabel={'...'}
                                  breakClassName={'break-me'}
                                  pageCount={totalPayment / size}
                                  marginPagesDisplayed={1}
                                  pageRangeDisplayed={1}
                                  onPageChange={handlePageClick}
                                  containerClassName={'pagination'}
                                  pageClassName={'page-cls'}
                                  activeClassName={'active'}
                                  forcePage={currentPage}
                                />
                                : null}
                          </nav>
                      } */}
                    </div>
                  </div>
                </main>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ChangePassword showModal={showModal} setShowModal={setShowModal} />
      <ChangeTnxPasswordModel showModal2={showModal2} setShowModal2={setShowModal2} />
      <ViewGameModal
        rowData={rowData}
        viewModal={viewModal}
        handleViewHideModal={handleViewHideModal}
        deleteGame2={deleteGame2}
      />
    </>
  );
};

export default Dashboard;
