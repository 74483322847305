import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DashboardJSON from './WalletManagement.json'
import { gamesActions, marketActions, walletActions, userActions } from '../../_actions';
import { FaEyeSlash } from "react-icons/fa";
import { FaEye } from "react-icons/fa";
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { FaWallet } from "react-icons/fa";
import Loader from '../../components/Loader/Loader';


// import Table from '../../components/Atoms/Table/Table';


const AddFund = () => {
  const dispatch = useDispatch();
  let [selectedFile, setSelectedFile] = useState(null);
  let [rowData, setRowData] = useState(null);
  let [createModal, setCreateModal] = useState(false);
  let [updateModal, setUpdateModal] = useState(false);
  let [viewModal, setViewModal] = useState(false);
  const [marketSelected, setMarketSelected] = useState([]);
  const [fieldsUpdate, setFieldsUpdate] = useState({});
  const [errorsUpdate, setErrorsUpdate] = useState({});
  const [eye, seteye] = useState(true);
  const [fieldsGame, setFieldsGame] = useState({});
  const [errorsGame, setErrorsGame] = useState({});
  const [selectedMarket, setSelectedMarket] = useState({});
  const [size, setSize] = useState(10);
  const [offset, setOffset] = useState(0);
  const [pageNo, setPageNo] = useState(1);

  const { allMarket } = useSelector(state => state.markets);
  const { userList, userTotal } = useSelector(state => state.users);
  const selector = useSelector(state => state)
  const { games, users } = selector ? selector : {};
  let { adminProfileDetails } = users ? users : {};
  let { balance, adminData } = adminProfileDetails ? adminProfileDetails : {}
  let { allUserList, loading } = games ? games : {};

  // console.log(adminProfileDetails)
  useEffect(() => {
    let userReq = {
      "keyWord": "",
      "pageNo": pageNo,
      "sortBy": "slug",
      "sortOrder": "asc",
      "fromDate": "",
      "toDate": "",
      "size": size
    }
    dispatch(gamesActions.getAllUser());
    dispatch(userActions.getAdminProfile());
  }, []);

  const inputChangeCreate = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setFieldsGame(prevState => ({ ...prevState, [name]: value }));
    setErrorsGame(prevState => ({ ...prevState, [name]: "" }));
  };

  const createGameSubmit = (e) => {
    e.preventDefault();
    if (handleValidationCreateGame()) {
      const { userId, amount,password } = fieldsGame;
      dispatch(walletActions.depositeBalanceByAdmin({
        userId: userId,
        amount: amount,
        password:password,
      }));
      // setCreateModal(false);
      setFieldsGame({});

    }
  };

  const handleValidationCreateGame = () => {
    let formIsValid = true;
    let errors = {};

    if (!fieldsGame.userId || fieldsGame.userId === "") {
      formIsValid = false;
      errors.userId = "Select User";
    }

    if (!fieldsGame.amount || fieldsGame.amount === "") {
      formIsValid = false;
      errors.amount = "Enter amount";
    }
    if (!fieldsGame.password || fieldsGame.password === "") {
      formIsValid = false;
      errors.password = "Enter Tnx Password";
    }

    setErrorsGame(errors);
    return formIsValid;
  };
  let sessioData = JSON.parse(sessionStorage.getItem("adminuser"))
  let { roleId } = sessioData ? sessioData : {}
  console.log(balance)

  return (
    <>
      <Loader loading={loading} />
      <div className="z-0 overflow-hidden overflow-y-auto focus:outline-none">
        <div className="relative">
          <div className="relative z-20 flex min-h-screen">
            <div className="flex w-full overflow-hidden ">
              <div className="flex flex-col flex-1 overflow-hidden">
                <main className="relative flex-1 ">
                  <div className="p-3 2xl:p-10 sm:p-5">
                    <div className="mx-auto max-w-screen-3xl">
                      <sectiion className="flex justify-between my-4 ">
                        <div className='text-xl font-semibold'>Dashboard{" "}/{" "}<span className='text-[#7D1970]'>Add Fund</span></div>
                      </sectiion>

                      <div className='bg-white rounded-xl'>
                        <div className='flex justify-between items-center bg-[#F64401]  rounded-t-md px-3 border-b'>
                          <span className="p-5 text-lg text-left rtl:text-right text-white">
                            Add Balance In User Wallet
                          </span>
                          <span>
                            {roleId && roleId.toString() == "1" ? <div className=' text-white  text-xl bg-[#F64401] p-2 rounded-lg flex gap-2 justify-center items-center'><FaWallet className=' text-white' /> {balance?.toString()}</div>
                              :
                              ""
                            }
                          </span>
                        </div>



                        <div className='p-5 space-y-2'>
                          <div className="flex flex-col md:flex-row gap-2 ">
                            <div className="mt-1 shadow-sm w-full">
                              <label class="block text-gray-700 text-base mb-2" for="name">User List <span className='text-red-700'>*</span> :</label>
                              <select className="w-full px-3 py-2 text-sm bg-white border border-gray-400 rounded-lg focus:outline-none capitalize"
                                id="userId" name="userId" placeholder="name"
                                value={fieldsGame && fieldsGame["userId"] ? fieldsGame["userId"] : ""} type="text" onChange={inputChangeCreate} >
                                <option value="">Select User</option>

                                {allUserList && allUserList.length > 0 ? allUserList.map((element, index) => (
                                  <option value={element && element._id ? element._id : ""}>{element && element.userName ? element.userName : ""}</option>
                                )) : null}
                              </select>
                              {errorsGame && errorsGame["userId"] ?
                                <div className="text-yellow-600 invalid-feedback">
                                  {errorsGame["userId"]}
                                </div>
                                : null}
                            </div>
                            <div className="w-full mt-1 shadow-sm">
                              <label class="block text-gray-700 text-base mb-2" for="gamename">Amount <span className='text-red-700'>*</span> :</label>
                              <input className="w-full px-3 py-2 text-sm bg-white border border-gray-400 rounded-lg focus:outline-none"
                                id="amount" name="amount" placeholder="Enter Amount" value={fieldsGame && fieldsGame["amount"] ? fieldsGame["amount"] : ""} type="number" onChange={inputChangeCreate} />
                              {errorsGame && errorsGame["amount"] ?
                                <div className="text-yellow-600 invalid-feedback">
                                  {errorsGame["amount"]}
                                </div>
                                : null}
                            </div>
                            <div className="w-full mt-1 shadow-sm">
                            
                           
                              <label class="block text-gray-700 text-base mb-2" for="gamename">Tnx Password <span className='text-red-700'>*</span> :</label>
                              <div className=' relative'>

                              <input className="w-full px-3 py-2 text-sm bg-white border border-gray-400 rounded-lg focus:outline-none"
                                id="password" name="password" placeholder="Enter Tnx password" value={fieldsGame && fieldsGame["password"] ? fieldsGame["password"] : ""} type={eye?"password":`text`} onChange={inputChangeCreate} />
                                {eye?<FaEyeSlash className=' absolute top-3 right-2' onClick={()=>seteye((prev)=>!prev)}/>:<FaEye className=' absolute top-3 right-2' onClick={()=>seteye((prev)=>!prev)} />}
                                </div>
                              {errorsGame && errorsGame["password"] ?
                                <div className="text-yellow-600 invalid-feedback">
                                  {errorsGame["password"]}
                                </div>
                                : null}
                            </div>
                          </div>
                          <div className="w-full flex justify-end">
                            <button className="flex justify-center px-4 py-2 text-base font-semibold text-white capitalize  transition duration-150 ease-in-out bg-[#7D1970] border border-[#7D1970] rounded-xl focus:outline-none" type="button" onClick={createGameSubmit}>Submit</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </main>
              </div>
            </div>
          </div>
        </div >
      </div >
    </>
  );
};

export default AddFund;
