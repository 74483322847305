import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { bankActions } from '../../../_actions'
import { FaEye } from "react-icons/fa6";
import ReactPaginate from 'react-paginate';
import moment from 'moment'

const ClientPaymentList = ({userId}) => {
    const selector = useSelector(state => state)
    const dispatch = useDispatch()
    const [selectedImage, setSelectedImage] = useState(null);
    const [toDate2, settoDate2] = useState('');
    const [offset2, setOffset2] = useState(0);
    const [fromDate2, setfromDate2] = useState('');
    const [currentPage, setCurrentPage] = useState(0);
    const [size, setSize] = useState(10);
    const [pageNo, setPageNo] = useState(1);
    const [keyWord2, setkeyWord2] = useState('');
    const [sortBy, setsortBy] = useState("asc");

    console.log("userId",userId)


    useEffect(() => {
        let data = {
            "fromDate": "",
            "toDate": "",
            "keyWord": "",
            "sortBy": "asc",
            "sortOrder": "",
            "pageNo": 1,
            "size": size,
            "userId":userId
        }
        dispatch(bankActions.getClientPaymentList(data))

    }, [userId])
    const handleImageClick = (imageUrl) => {
        setSelectedImage(imageUrl);
    };

    const closeModal = () => {
        setSelectedImage(null);
    };

    const handlePageClick1 = (data) => {

        let offset = Math.ceil(data.selected * size);
        setCurrentPage(data.selected)
        setOffset2(offset);
        setPageNo(data.selected + 1);

        let myBetReq = {
            "fromDate": fromDate2,
            "toDate": toDate2,
            "keyWord": keyWord2,
            "sortBy": "",
            "sortOrder": sortBy,
            "pageNo": data.selected + 1,
            "size": size,
            "userId":userId

        }
        dispatch(bankActions.getClientPaymentList(myBetReq))


    }

    const paymentListLength = selector?.bank?.paymentList || 0;
    let {bank}=selector?selector:{}
    let {paymentList} = bank?bank:{}
    let {total,list} = paymentList?paymentList:{}


    return (
        <div className='bg-white shadow rounded-md p-4 mx-2'>
            <div className="text-gray-700 text-xl font-bold whitespace-nowrap border-b pb-2">Client Payment History</div>




            <div className=' overflow-hidden overflow-x-auto'>
                <table className="w-full text-sm text-left text-gray-500">
                    <thead className="text-base text-gray-700 capitalize bg-gray-50">
                        <tr>
                            <th className="px-2 py-3">S.No.</th>
                            <th className="px-2 py-3">User Name</th>

                            <th className="px-2 py-3">Image</th>
                            <th className="px-2 py-3">status</th>
                            <th className="px-2 py-3">Date</th>
                            <th className="px-2 py-3">Amount</th>

                        </tr>
                    </thead>
                    {list && list.length > 0 ? list.map((element, index) => (
                        <React.Fragment key={index}>
                            <tr key={index} className="bg-white  border-gray-300 capitalize">
                                <td>   {offset2 + index + 1}</td>
                                <th className="px-6 py-2 font-medium  whitespace-nowrap ">
                                    {element && element?.userId?.userName
                                        ? element?.userId?.userName
                                        : "-"}
                                </th>

                                <th className="px-6 py-2 font-medium  whitespace-nowrap ">

                                    <FaEye
                                        onClick={() => handleImageClick(element?.image)}
                                        className='text-xl cursor-pointer text-[#F64401]'
                                    />

                                </th>
                                <th
                                    className={`px-4 py-2 font-medium whitespace-nowrap ${element?.status === 0 ? 'text-orange-600' : // Pending
                                        element?.status === 1 ? 'text-green-600' : // Success
                                            element?.status === 2 ? 'text-red-600' : // Failed
                                                'text-black' // Default color if no status
                                        }`}
                                >
                                    {element && (
                                        element.status === 0 ? "Pending" :
                                            element.status === 1 ? 'Success' :
                                                element.status === 2 ? "Failed" :
                                                    "-"
                                    )}
                                </th>


                                <td className="px-2 py-2 whitespace-nowrap  uppercase">
                                        {element && element.createdAt
                                          ? moment(new Date(parseInt(element.createdAt)))
                                            .utcOffset("+05:30")
                                            .format("DD-MM-YYYY hh:mm a")
                                          : "-"}
                                      </td>

                                <td className="px-6 py-2  whitespace-nowrap uppercase">
                                    {element && element?.amount ? element?.amount : "-"}
                                </td>
                            </tr>
                        </React.Fragment>
                    )) :
                        <tr>
                            <td className="px-6 py-4">
                                No data found
                            </td>
                        </tr>
                    }
                    <tbody>

                    </tbody>

                </table>
            </div>
            {
                selectedImage && (
                    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50 p-4">
                        <div className="bg-white rounded overflow-hidden max-w-full md:max-w-2xl mx-auto">
                            <img
                                src={selectedImage}
                                alt='Selected'
                                className='w-full h-auto max-h-[80vh] object-contain' // Limits height to 80% of viewport height
                            />
                            <button
                                onClick={closeModal}
                                className="mt-4 w-full bg-red-500 text-white py-2 rounded">
                                Close
                            </button>
                        </div>
                    </div>


                )
            }


            {
                total && total > size ? (
                    <ReactPaginate
                        previousLabel={'Prev'}
                        nextLabel={'Next'}
                        breakLabel={'...'}
                        breakClassName={'break-me'}
                        pageCount={Math.ceil(total / size)}
                        marginPagesDisplayed={1}
                        pageRangeDisplayed={1}
                        onPageChange={handlePageClick1}
                        containerClassName={'pagination'}
                        pageClassName={'page-cls'}
                        activeClassName={'active'}
                        forcePage={currentPage}
                    />
                ) : null
            }
        </div >
    )
}

export default ClientPaymentList